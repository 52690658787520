import { useAlerting } from "../../../../hooks";
import { useZusDialogStore, useZusTranslatePrefixStore } from "../../../../zustand/store";
import { useEffect, useState } from "react";
import useGetListParams from "../../eshopOrder/helpers/useGetListParams";
import { ApiResult } from "../../../../api/types";
import { GetListRes } from "../../eshopOrder/types/GetListRes";
import useTableRows from "../../eshopOrder/helpers/useTableRows";
import api from "../../../../api";
import { downloadFiles, getFullApiResponse } from "../../../../utils";
import { convertToExportRows } from "../../../common/filterTable/helpers/convertToExportRows";
import { useQuery } from "react-query";
import useLocalTranslate from "../../eshopOrder/helpers/useLocalTranslate";
import { initFields } from "../../eshopOrder/config";
import { FilterFields } from "../../eshopOrder/types/FilterFields";

const useEShopOrder = () => {
  const zusDialog = useZusDialogStore();
  const { t, tc, translatePrefix } = useLocalTranslate()
  const { setTranslatePrefix } = useZusTranslatePrefixStore();
  const { alerting } = useAlerting();
  const { getListParams } = useGetListParams();

  const [page, setPage] = useState(0);
  const [fields, setFields] = useState<FilterFields>(initFields);
  const [listParams, setListParams] = useState(getListParams(fields));
  const [list, setList] = useState<ApiResult<GetListRes>>({ rows: [], count: 0 });

  useEffect(() => {
    setTranslatePrefix(translatePrefix)
    return () => {
      zusDialog.close()
    }
  }, []);

  const { totalCounts, convertToTableRows } = useTableRows({ apiList: list });

  const handleQuery = () => {
    zusDialog.openExtra('loadingDialog');
    const { currentTime, ...cleanParams } = listParams;
    return api.EShop.getEShopOrder.getEShopOrder(cleanParams);
  };

  const handleSuccess = (data: ApiResult<GetListRes>) => {
    if (!data) return;
    console.log('data', data)
    setList(data);
  };

  const handleSettled = () => zusDialog.closeExtra();
  useQuery(['getAssetCustody', listParams], handleQuery, {
    onSettled: handleSettled,
    onSuccess: handleSuccess,
  });

  const onExport = async () => {
    const params = getListParams(fields);
    const { ...cleanParams } = params;

    if (totalCounts === 0) {
      return alerting('error', tc('no_data_export'));
    }

    const apiFn = (page: number, pageSize: number, signal: any) =>
      api.EShop.getEShopOrder.getExport({ ...cleanParams, page, pageSize }, { signal });

    const rawRes = await getFullApiResponse(apiFn, totalCounts, true);

    if (rawRes.length === 0) return;

    const omitKeys = ['id', 'rawData'];
    const tableRows = convertToTableRows({ rows: rawRes } as ApiResult<GetListRes>);
    const exportRows = convertToExportRows({ data: tableRows, omitKeys, translateFunc: t });

    downloadFiles(`E-Shop Order`, exportRows, {});
  };

  const onPageChange = (page: number) => {
    setPage(page);
    setListParams({ ...listParams, page });
  };

  const onFilterSearch = () => {
    setPage(0);
    setListParams({
      ...getListParams(fields),
      page: 0,
      currentTime: Date.now(),
    });
  };

  return {
    list,
    page,
    fields,
    setFields,
    onExport,
    onPageChange,
    onFilterSearch,
  }
}

export default useEShopOrder