import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IprogramList } from '../api/types';
import { RootState } from './store';

const initialState: {
  allProgramList: Record<string, string>;
  programCurrencyList: Record<string, string>;
  allCurrencyList: Record<string, string>;
  programCustomerLvList: Record<string, string>;
  programMaxCreditLimitList: Record<string, string>;
  programCreditLimitList: Record<string, string>;
  allProgramRaw: (IprogramList & { customerLevel: string; source: number })[];
} = {
  allProgramList: {},
  programCurrencyList: {},
  allCurrencyList: {},
  programCustomerLvList: {},
  programMaxCreditLimitList: {},
  programCreditLimitList: {},
  allProgramRaw: [],
};

export const programListSlice = createSlice({
  name: 'programList',
  initialState,
  reducers: {
    setProgramList: (
      state,
      action: PayloadAction<(IprogramList & { customerLevel: string; source: number })[]>
    ) => {
      if (!action.payload) {
        return;
      }
      state.allProgramList = action.payload.reduce(
        (acc: Record<string, string>, { programName }) => {
          // hide empty program name value
          if (programName === '') {
            return acc;
          }

          return {
            ...acc,
            [programName]: programName,
          };
        },
        {}
      );

      state.allProgramRaw = action.payload;

      state.programCurrencyList = action.payload.reduce(
        (acc: Record<string, string>, { programName, homeCurrency }) => ({
          ...acc,
          [programName]: homeCurrency,
        }),
        {}
      );
      state.allCurrencyList = action.payload.reduce(
        (acc: Record<string, string>, { homeCurrency }) => {
          if (acc[homeCurrency]) {
            return acc;
          }

          return {
            ...acc,
            [homeCurrency]: homeCurrency,
          };
        },
        {}
      );
      state.programCustomerLvList = action.payload.reduce(
        (acc: Record<string, string>, { programName, customerLevel }) => ({
          ...acc,
          [programName]: customerLevel,
        }),
        {}
      );
      state.programMaxCreditLimitList = action.payload.reduce(
        (acc: Record<string, string>, { programName, maxCreditLimit }) => ({
          ...acc,
          [programName]: maxCreditLimit,
        }),
        {}
      );
      state.programCreditLimitList = action.payload.reduce(
        (acc: Record<string, string>, { programName, creditLimit }) => ({
          ...acc,
          [programName]: creditLimit,
        }),
        {}
      );
    },
  },
});

export const { setProgramList } = programListSlice.actions;

export const selectProgramList = (state: RootState) => state.programList;
export default programListSlice.reducer;
