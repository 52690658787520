
import { MerchantPortal } from "@wallet-manager/pfh-pmp-node-def-types";
import axios from '../axiosInstance';

const getAll = async (params: any, config?: any) => {
  const input = {
    params: params,
    ...config,
  };
  return await axios.get(MerchantPortal.EptCobrandManagement.CardFaceOverview.getAll, input);
}

export default {
  getAll
}