import { useTabs } from "../../../../hooks";
import useTranslation, { Iprefix } from "../../../../hooks/useTranslation";

const useLocalTranslate = () => {

  const translatePrefix: Iprefix = 'orderTransaction';

  const { t, tc, translate } = useTranslation(translatePrefix);

  const { Tabs } = useTabs([{ name: tc('table'), value: '' }]);

  return {
    t,
    tc,
    Tabs,
    translate,
    translatePrefix
  }
}

export default useLocalTranslate