import FilterContainer from "../../../common/filterTable/layout/FilterContainer"
import CollapsibleFilters from '../../../common/filterTable/components/Filters/CollapsibleFilters';
import useCollapsibleFilters from "../../../common/filterTable/components/Filters/CollapsibleFilters/hooks/useCollapsibleFilters";
import FilterSectionActionRow from "../../../common/filterTable/layout/FilterSectionActionRow";
import ToggleFilterButton from "../../../common/filterTable/components/Filters/CollapsibleFilters/components/ToggleFilterButton";
import { Filter } from "../../../common/filterTable/types";
import { TableColumnKey } from "../types/TableColumnKeys";
import ProgramNameMultipleSelection from "../../../common/filterTable/components/Filters/ProgramNameMultipleSelection";
import { FilterFields, FilterFieldsKeys } from "../types/FilterFields";
import { SetStateAction, useEffect } from "react";
import { initFields } from "../config";
import CardProfileNameSingleSelection from "../../../common/filterTable/components/Filters/CardProfileNameSingleSelection";

interface ViewFilterProps {
  fields: FilterFields;
  setFields: React.Dispatch<SetStateAction<FilterFields>>;
  onSearch: () => void;
}

const ViewFilter = (props: ViewFilterProps) => {
  const { fields, setFields, onSearch } = props;
  const { isCollapse, toggleFilterCollapse } = useCollapsibleFilters();

  useEffect(() => {
    toggleFilterCollapse()
  }, [])

  const updateField = (fieldName: string, value: any) => {
    setFields((fields) => {
      return {
        ...fields,
        [fieldName]: value,
      };
    });
  }

  const filters: Filter[] = [
    {
      labelKey: TableColumnKey.ProgramName,
      filter: <ProgramNameMultipleSelection
        value={fields[FilterFieldsKeys.ProgramNames]}
        onChange={(value: string[]) => (updateField(FilterFieldsKeys.ProgramNames, value))}
        onClear={() => {
          setFields((fields) => ({
            ...fields,
            [FilterFieldsKeys.ProgramNames]: [],
          }))
        }}
      />,
    },
    {
      labelKey: TableColumnKey.CardProfileName,
      filter: <CardProfileNameSingleSelection
        value={fields[FilterFieldsKeys.CardProfileName]}
        onChange={(value: string) => {
          updateField(FilterFieldsKeys.CardProfileName, value);
        }}
      />
    }
  ];


  return (
    <>
      <FilterSectionActionRow>
        <ToggleFilterButton
          isCollapse={isCollapse}
          onClick={toggleFilterCollapse}
        />
      </FilterSectionActionRow>
      <FilterContainer>
        <CollapsibleFilters
          filters={filters}
          isCollapse={isCollapse}
          onReset={() => setFields(initFields)}
          onSearch={onSearch}
        />
      </FilterContainer>
    </>
  )
}

export default ViewFilter