import { COLUMN_WIDTH } from "../../../../constants/ColumnWidth";
import { useGenGridCol } from "../../../../utils/ComponentHelper";
import { TableColumnKey } from "../types/TableColumnKey";

const useTableColumns = () => {
  const columns = [
    useGenGridCol(TableColumnKey.PurchaseOrderId, { minWidth: 150 }),
    useGenGridCol(TableColumnKey.CustomerId, {
      minWidth: COLUMN_WIDTH.CustomerId.MinWidth,
    }),
    useGenGridCol(TableColumnKey.CustomerNumber, {
      minWidth: COLUMN_WIDTH.CustomerNumber.MinWidth,
    }),
    useGenGridCol(TableColumnKey.PaymentType),
    useGenGridCol(TableColumnKey.Currency),
    useGenGridCol(TableColumnKey.Amount),
    useGenGridCol(TableColumnKey.PurchaseStatus),
    useGenGridCol(TableColumnKey.LedgerTransactionId),
    useGenGridCol(TableColumnKey.LedgerRequestStatus, { minWidth: 150 }),
    useGenGridCol(TableColumnKey.MerchantOrderId),
    useGenGridCol(TableColumnKey.FreezeMerchantOrderId),
    useGenGridCol(TableColumnKey.From),
    useGenGridCol(TableColumnKey.To),
    useGenGridCol(TableColumnKey.Sequence),
    useGenGridCol(TableColumnKey.TransactionStep),
    useGenGridCol(TableColumnKey.ErrorReason),
    useGenGridCol(TableColumnKey.CreationTime, {
      minWidth: COLUMN_WIDTH.CreatedDate.MinWidth,
    }),
    useGenGridCol(TableColumnKey.LastModifiedTime, {
      minWidth: COLUMN_WIDTH.CreatedDate.MinWidth,
    }),
  ]
  return { columns };
}
export default useTableColumns;