import { FilterFieldsKeys } from "./types/FilterFields";

export const initFields = {
  [FilterFieldsKeys.CreatedDateFrom]: '',
  [FilterFieldsKeys.CreatedDateTo]: '',
  [FilterFieldsKeys.LastModifiedTimeFrom]: '',
  [FilterFieldsKeys.LastModifiedTimeTo]: '',
  [FilterFieldsKeys.CustomerNumber]: '',
  [FilterFieldsKeys.CustomerId]: '',
  [FilterFieldsKeys.PurchaseStatus]: '',
  [FilterFieldsKeys.LedgerRequestStatus]: '',
  [FilterFieldsKeys.PurchaseOrderId]: '',
  [FilterFieldsKeys.LedgerTransactionId]: '',
};