import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';

import { MerchantPortalFeatureCode } from '@wallet-manager/pfh-pmp-node-def-types';
import {
  EnumApprovalStatus,
  EnumTcspAccountApplicationStatus,
} from '@wallet-manager/pfh-pmp-node-def-types/dist/src/DbModel/Master';

import APIs from '../../../api';
import { ApiResult } from '../../../api/types';
import { ExportBtn } from '../../../components/Button';
import FilterBarCreateRecordButton from '../../../components/Button/FilterBarCreateRecordButton';
import { useDatePicker } from '../../../components/DatePicker';
import { DivideLine } from '../../../components/Layout';
import ApprovalProgressDialog from '../../../features/approval/components/ApprovalProgressDialog';
import ApprovalProgressTableCell from '../../../features/approval/components/ApprovalProgressTableCell';
import ApproveRejectCell from '../../../features/approval/components/ApproveRejectCell';
import ApproveRejectDialog from '../../../features/approval/components/ApproveRejectDialog';
import { ApproveHistory } from '../../../features/approval/types/ApproveHistory';
import ToggleFilterButton from '../../../features/common/filterTable/components/Filters/CollapsibleFilters/components/ToggleFilterButton';
import useCollapsibleFilters from '../../../features/common/filterTable/components/Filters/CollapsibleFilters/hooks/useCollapsibleFilters';
import Table from '../../../features/common/filterTable/components/Table';
import { convertToExportRows } from '../../../features/common/filterTable/helpers/convertToExportRows';
import FilterContainer from '../../../features/common/filterTable/layout/FilterContainer';
import FilterSectionActionRow from '../../../features/common/filterTable/layout/FilterSectionActionRow';
import FilterTableLayoutContainer from '../../../features/common/filterTable/layout/FilterTableLayoutContainer';
import TableContainer from '../../../features/common/filterTable/layout/TableContainer';
import { useAlerting, usePermission, useTabs, useTranslation } from '../../../hooks';
import { useAppSelector } from '../../../reducer/hooks';
import { selectProfile } from '../../../reducer/profileSlice';
import { downloadFiles, getFullApiResponse } from '../../../utils';
import { useZusDialogStore, useZusTranslatePrefixStore } from '../../../zustand/store';
import ApproveRejectDialogContent from './components/ApproveRejectDialogContent';
import CreateRequestDialog from './components/CreateRequestDialog';
import FilterSection from './components/FilterSection';
import useGetListParams from './helpers/useGetListParams';
import useTableColumns from './helpers/useTableColumns';
import useTableRows from './helpers/useTableRows';
import { FilterFields, FilterFieldsKeys } from './types/FilterFields';
import { GetListRes } from './types/GetListRes';
import { TableRow } from './types/TableRow';

const initFields = {
  [FilterFieldsKeys.createdDateFrom]: '',
  [FilterFieldsKeys.createdDateTo]: '',
  [FilterFieldsKeys.lastModifiedTimeFrom]: '',
  [FilterFieldsKeys.lastModifiedTimeTo]: '',
  [FilterFieldsKeys.programAgentId]: '',
  [FilterFieldsKeys.status]: '',
  [FilterFieldsKeys.transactionType]: '',
};

const translatePrefix = 'paRebateAdjustmentRequest';

const PaRebateAdjustmentRequest = () => {
  const { getListParams } = useGetListParams();

  const [page, setPage] = useState(0);
  const [fields, setFields] = useState<FilterFields>(initFields);
  const [listParams, setListParams] = useState(getListParams(fields));
  const [list, setList] = useState<ApiResult<GetListRes>>({ rows: [], count: 0 });

  const [approveHistoryRes, setApproveHistoryRes] = useState<ApproveHistory[]>([]);
  const [selectedRow, setSelectedRow] = useState<any>({});

  const [isApprovalProgressDialogOpen, setIsApprovalProgressDialogOpen] = useState(false);
  const [isApproveRejectDialogOpen, setIsApproveRejectDialogOpen] = useState(false);
  const [isCreateRequestDialogOpen, setIsCreateRequestDialogOpen] = useState(false);

  const { isCollapse, toggleFilterCollapse } = useCollapsibleFilters();

  const profile = useAppSelector(selectProfile);

  const columns = useTableColumns({ renderApprovalProgressCell, renderOperationsCell });
  const { rows, totalCounts, convertToTableRows } = useTableRows({ apiList: list });

  const zusDialog = useZusDialogStore();
  const { alerting } = useAlerting();
  const { hasPermission } = usePermission();
  const { setTranslatePrefix } = useZusTranslatePrefixStore();
  const { t, tc } = useTranslation(translatePrefix);
  const { Tabs } = useTabs([{ name: tc('table'), value: '' }]);

  const isShowExportBtn = hasPermission(
    MerchantPortalFeatureCode.Reward.PaRebateAdjustmentRequest.Export
  );
  const hasApprovePermission = hasPermission(
    MerchantPortalFeatureCode.Reward.PaRebateAdjustmentRequest.Approve.prefix
  );
  const hasRejectPermission = hasPermission(
    MerchantPortalFeatureCode.Reward.PaRebateAdjustmentRequest.Reject
  );
  const hasCreateApplicationPermission = hasPermission(
    MerchantPortalFeatureCode.Reward.PaRebateAdjustmentRequest.CreateRequest
  );

  const getApproveHistory = () => {
    const approveHistory = approveHistoryRes;

    if (!approveHistory || approveHistory?.length === 0) return [];

    const isTxRejected = selectedRow.rawData.status === EnumTcspAccountApplicationStatus.Rejected;

    if (isTxRejected) {
      return approveHistory.filter((item: any) => {
        return item.status !== EnumApprovalStatus.Pending;
      });
    }

    return approveHistory;
  };

  const approveHistory = getApproveHistory();
  const approvalRequired = selectedRow?.rawData?.approvalsRequired;
  const approvalCount = selectedRow?.rawData?.approvalsCount;

  const DateObj = {
    CreationTime: useDatePicker(),
    LastModifiedTime: useDatePicker(),
  };

  const fetchApproveDetails = async (id: string) => {
    zusDialog.openExtra('loadingDialog');
    const res = await APIs.Reward.paRebateAdjustmentRequest.getApproveDetails({
      requestRecordId: id,
    });
    zusDialog.closeExtra();

    if (!res) return;

    setApproveHistoryRes(res);
  };

  function renderApprovalProgressCell({ row }: { row: TableRow }) {
    const rowRawData = row.rawData;

    return (
      <ApprovalProgressTableCell
        approvalsCount={row.rawData.approvalsCount}
        approvalsRequired={row.rawData.approvalsRequired}
        applicationStatus={row.rawData.status}
        onButtonClick={async () => {
          await fetchApproveDetails(String(rowRawData.id));

          setSelectedRow(row);
          setIsApprovalProgressDialogOpen(true);
        }}
      />
    );
  }

  function renderOperationsCell({ row }: { row: TableRow }) {
    const isDisabled = row.rawData.status !== EnumTcspAccountApplicationStatus.Pending;

    const handleApproveRejectCellClick = async () => {
      const rowRawData = row.rawData;

      await fetchApproveDetails(String(rowRawData.id));

      setSelectedRow(row);
      setIsApproveRejectDialogOpen(true);
    };

    return (
      <>
        <ApproveRejectCell
          onClick={handleApproveRejectCellClick}
          isDisabled={isDisabled}
          isHide={!hasApprovePermission && !hasRejectPermission}
        />
      </>
    );
  }

  const onExport = async () => {
    const params = getListParams(fields);
    const { currentTime, ...cleanParams } = params;

    if (totalCounts === 0) {
      return alerting('error', tc('no_data_export'));
    }

    const apiFn = (page: number, pageSize: number, signal: any) =>
      APIs.Reward.paRebateAdjustmentRequest.getExport(
        { ...cleanParams, page, pageSize },
        { signal }
      );

    const rawRes = await getFullApiResponse(apiFn, totalCounts, true);

    if (rawRes.length === 0) return;

    const omitKeys = ['id', 'rawData'];
    const tableRows = convertToTableRows({ rows: rawRes } as ApiResult<GetListRes>);
    const exportRows = convertToExportRows({ data: tableRows, omitKeys, translateFunc: t });

    downloadFiles(`PA Rebate Adjustment Request`, exportRows, {});
  };

  const onPageChange = (page: number) => {
    setPage(page);
    setListParams({ ...listParams, page });
  };

  const onFilterSearch = () => {
    setPage(0);
    setListParams({
      ...getListParams(fields),
      page: 0,
      currentTime: Date.now(),
    });
  };

  const onFilterReset = () => {
    setFields(initFields);
    DateObj.CreationTime.clearDate();
    DateObj.LastModifiedTime.clearDate();
  };

  const refreshTable = () => {
    setListParams({ ...listParams, currentTime: Date.now() });
  };

  const Dialogs = () => {
    return (
      <>
        <CreateRequestDialog
          isOpen={isCreateRequestDialogOpen}
          selectedRow={selectedRow}
          closeDialog={() => setIsCreateRequestDialogOpen(false)}
          refreshTable={refreshTable}
        />

        <ApprovalProgressDialog
          isOpen={isApprovalProgressDialogOpen}
          closeDialog={() => setIsApprovalProgressDialogOpen(false)}
          approveHistory={approveHistory}
        />

        <ApproveRejectDialog
          isOpen={isApproveRejectDialogOpen}
          closeDialog={() => setIsApproveRejectDialogOpen(false)}
          refreshTable={refreshTable}
          data={{
            operator: profile.email,
            createdBy: selectedRow.createdBy,
            approvalsRequired: approvalRequired,
            approvalsCount: approvalCount,
            approveHistory: approveHistory,
            isHideSubTitleRow: true,
          }}
          actions={{
            approve: {
              apiFunc: APIs.Reward.paRebateAdjustmentRequest.approve,
              permission: MerchantPortalFeatureCode.Reward.PaRebateAdjustmentRequest.Approve.prefix,
            },
            reject: {
              apiFunc: APIs.Reward.paRebateAdjustmentRequest.reject,
              permission: MerchantPortalFeatureCode.Reward.PaRebateAdjustmentRequest.Reject,
            },
          }}
          customErrorMsg={{
            sameApprover: tc('no_same_approver_2'),
          }}
        >
          <ApproveRejectDialogContent rowData={selectedRow} />
        </ApproveRejectDialog>
      </>
    );
  };

  const handleSettled = () => zusDialog.closeExtra();

  const handleSuccess = (data: ApiResult<GetListRes>) => {
    if (!data) return;
    setList(data);
  };

  const handleQuery = () => {
    zusDialog.openExtra('loadingDialog');
    const { currentTime, ...cleanParams } = listParams;
    return APIs.Reward.paRebateAdjustmentRequest.getAll(cleanParams);
  };
  useQuery(['getPaRebateAdjustmentRequest', listParams], handleQuery, {
    onSettled: handleSettled,
    onSuccess: handleSuccess,
  });

  useEffect(() => setTranslatePrefix(translatePrefix), []);

  useEffect(() => {
    // sync fields state with date picker
    setFields((fields) => {
      return {
        ...fields,
        [FilterFieldsKeys.createdDateFrom]: DateObj.CreationTime.start,
        [FilterFieldsKeys.createdDateTo]: DateObj.CreationTime.end,
        [FilterFieldsKeys.lastModifiedTimeFrom]: DateObj.LastModifiedTime.start,
        [FilterFieldsKeys.lastModifiedTimeTo]: DateObj.LastModifiedTime.end,
      };
    });
  }, [
    DateObj.CreationTime.start,
    DateObj.CreationTime.end,
    DateObj.LastModifiedTime.start,
    DateObj.LastModifiedTime.end,
  ]);

  return (
    <FilterTableLayoutContainer>
      <Dialogs />

      <FilterContainer>
        <FilterSectionActionRow>
          <FilterBarCreateRecordButton
            title={t('createRequest')}
            onClick={() => setIsCreateRequestDialogOpen(true)}
            isShow={hasCreateApplicationPermission}
          />

          <ToggleFilterButton isCollapse={isCollapse} onClick={toggleFilterCollapse} />
        </FilterSectionActionRow>

        <FilterSection
          fields={fields}
          setFields={setFields}
          onSearch={onFilterSearch}
          onReset={onFilterReset}
          DateObj={DateObj}
          isCollapse={isCollapse}
        />
      </FilterContainer>

      <DivideLine />

      <TableContainer>
        <ExportBtn onExport={onExport} isShow={isShowExportBtn} />
        <Tabs>
          <Table
            columns={columns}
            rows={rows}
            rowCount={totalCounts}
            page={page}
            onPageChange={onPageChange}
          />
        </Tabs>
      </TableContainer>
    </FilterTableLayoutContainer>
  );
};

export default PaRebateAdjustmentRequest;
