export enum TableColumnKey {
  PurchaseOrderId = 'purchaseOrderId',
  CustomerId = 'customerId',
  CustomerNumber = 'customerNumber',
  PaymentType = 'paymentType',
  Currency = 'currency',
  Amount = 'amount',
  PurchaseStatus = 'purchaseStatus',
  LedgerTransactionId = 'ledgerTransactionId',
  LedgerRequestStatus = 'ledgerRequestStatus',
  MerchantOrderId = 'merchantOrderId',
  FreezeMerchantOrderId = 'freezeMerchantOrderId',
  From = 'from',
  To = 'to',
  Sequence = 'sequence',
  TransactionStep = 'transactionStep',
  ErrorReason = 'errorReason',
  CreationTime = 'creationTime',
  LastModifiedTime = 'lastModifiedTime'
}
