import exchangeRate from './exchangeRate';
import exchangeRateConfiguration from './exchangeRateConfiguration';
import referralCode from './referralCode';
import program from './program';
import rewardRateConfiguration from './rewardRateConfiguration';
import injectionFeeConfiguration from './injectionFeeConfiguration';
import cardFaceOverview from './cardFaceOverview';

export default {
  exchangeRate,
  exchangeRateConfiguration,
  referralCode,
  program,
  rewardRateConfiguration,
  injectionFeeConfiguration,
  cardFaceOverview
};
