import { toDisplayTime } from "../../../../utils";
import { LocalApiResult, MerchantCardProfile } from "../types/GetResult";
import { TableColumnKey } from "../types/TableColumnKeys";
import { TableRow } from "../types/TableRow";
import _ from 'lodash';

interface UseTableRowsProps {
  localApiResult: LocalApiResult;
}

type ConvertToTableRowsType = (list: MerchantCardProfile[]) => TableRow[];

const useTableRows = (props: UseTableRowsProps) => {
  const { localApiResult } = props;

  const convertToTableRows: ConvertToTableRowsType = (apiList) => {
    if (!apiList) return [];

    const tableRows = apiList?.map((row: MerchantCardProfile, index: any): TableRow => {
      return {
        id: index,
        [TableColumnKey.ProgramName]: row.programName,
        [TableColumnKey.CardProfileName]: row.cardProfileName,
        [TableColumnKey.LastModifiedTime]: toDisplayTime(row.lastModifiedDate),
        [TableColumnKey.CardFace]: '',
        rawData: row
      };
    })

    return tableRows

  }

  const rows = convertToTableRows(localApiResult.merchantCardProfile);

  return {
    rows,
    convertToTableRows
  };
}

export default useTableRows;