export enum TableColumnKey {
    PurchaseOrderId = 'purchaseOrderId',
    CustomerId = 'customerId',
    CustomerNumber = 'customer_number',
    Amount = 'amount',
    PurchaseStatus = 'purchaseStatus',
    EShopReferenceId = 'eShopReferenceId',
    CreatedDate = 'creationTime',
    LastModifiedDate = 'lastModifiedTime',
    Decimals = 'decimals',
    CreationTime = 'creation_time',
    LastModifiedTime = 'last_modified_time'
}
