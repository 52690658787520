import { FilterTable } from '../../../components/Layout';
import useOrderTransaction from '../../../features/eshop/orderTransaction/helpers/useOrderTransaction'
import ViewFilter from '../../../features/eshop/orderTransaction/component/ViewFilter';
import ViewTable from '../../../features/eshop/orderTransaction/component/ViewTable';

const OrderTransaction = () => {

  const {
    list,
    page,
    fields,
    setFields,
    onExport,
    onPageChange,
    onFilterSearch,
  } = useOrderTransaction();

  return (
    <FilterTable
      filter={<ViewFilter
        fields={fields}
        setFields={setFields}
        onSearch={onFilterSearch}
      />}
      table={<ViewTable
        list={list}
        page={page}
        onExport={onExport}
        onPageChange={onPageChange}
      />}
    />
  );
};

export default OrderTransaction;
