import { useEffect, useState } from "react";
import { useZusDialogStore, useZusTranslatePrefixStore } from "../../../../zustand/store";
import useLocalTranslate from "./useLocalTranslate";
import { FilterFields } from "../types/FilterFields";
import { initFields, initLocalApiResult } from "../config";
import { isEqual } from "lodash";
import { useAlerting } from "../../../../hooks";
import { useQuery } from "react-query";
import api from "../../../../api";
import { LocalApiResult } from "../types/GetResult";
import useGetListParams from "./useGetListParams";

const useCardFaceOverView = () => {
  const { alerting } = useAlerting();
  const zusDialog = useZusDialogStore();
  const { setTranslatePrefix } = useZusTranslatePrefixStore();
  const { translatePrefix } = useLocalTranslate();
  const { t } = useLocalTranslate();
  const { getListParams } = useGetListParams();

  const [page, setPage] = useState(0);
  const [fields, setFields] = useState<FilterFields>(initFields);
  const [isInit, setisInit] = useState(true)
  const [localApiResult, setLocalApiResult] = useState<LocalApiResult>(initLocalApiResult);
  const [listParams, setListParams] = useState(getListParams(fields));

  useEffect(() => {
    setTranslatePrefix(translatePrefix)
    return () => {
      zusDialog.close();
    }
  }, [])

  const handleQuery = () => {
    zusDialog.openExtra('loadingDialog');
    const { currentTime, ...cleanParams } = listParams;
    return api.CobrandManagement.cardFaceOverview.getAll(cleanParams);
  }

  const handleSuccess = (data: LocalApiResult) => {
    if (!data) return;
    setLocalApiResult(data);
  }

  const handleSettled = () => zusDialog.closeExtra();

  useQuery(['getCardFaceOverview', listParams], handleQuery, {
    enabled: !isInit,
    refetchOnMount: false,
    onSettled: handleSettled,
    onSuccess: handleSuccess
  });

  const onSearch = () => {
    if (isEqual(fields, initFields)) {
      return alerting('error', t('selectFilterWarning'));
    }
    setisInit(false)
    setListParams({
      ...getListParams(fields),
      page: 0,
      currentTime: Date.now()
    });
  }


  return {
    localApiResult,
    fields,
    isInit,
    page,
    setFields,
    onSearch,
    setPage
  }
}

export default useCardFaceOverView;