import React from 'react';

const CardFaceImage = ({ url }: { url: string }) => {
  return (
    <div style={{ margin: '4px' }}>
      {url ? (
        <div
          style={{
            width: '160px',
            height: '120px',
            background: `url(${url}) no-repeat center center/contain`,
          }}
        />
      ) : (
        //   For the alt fallback text
        <img src={url} alt={'Card Face'} />
      )}
    </div>
  );
};

export default CardFaceImage;
