import { GridRenderCellParams } from "@mui/x-data-grid";
import { useGenGridCol } from "../../../../utils/ComponentHelper";
import { TableColumnKey } from "../types/TableColumnKeys";

interface IProps {
  renderCardFaceCell: (params: GridRenderCellParams<any, any, any>) => React.ReactNode;
}

const useTableColumns = (props: IProps) => {
  const { renderCardFaceCell } = props;
  const columns = [
    useGenGridCol(TableColumnKey.ProgramName),
    useGenGridCol(TableColumnKey.CardProfileName),
    useGenGridCol(TableColumnKey.LastModifiedTime),
    useGenGridCol(TableColumnKey.CardFace, { renderCell: renderCardFaceCell })
  ]
  return { columns };
}

export default useTableColumns;