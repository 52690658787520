import { MerchantPortal } from '@wallet-manager/pfh-pmp-node-def-types';

import axios from '../axiosInstance';

const getEShopOrder = async (params: any, config?: any) => {
  const { pageSize, page, ...rest } = params;

  const input = {
    params: {
      ...rest,
      limit: pageSize,
      offset: pageSize * page,
    },
    ...config,
  };
  return axios.get(MerchantPortal.EptEShop.order.getAll, input)
};

const getExport = async (params: any, config?: any) => {
  const { pageSize, page, ...rest } = params;

  const input = {
    params: {
      ...rest,
      limit: pageSize,
      offset: pageSize * page,
    },
    ...config,
  };
  return axios.get(MerchantPortal.EptEShop.order.export, input)

}

export default { getEShopOrder, getExport }
