import Table from '../../../common/filterTable/components/Table';
import TableContainer from '../../../common/filterTable/layout/TableContainer';
import useTableRows from '../helpers/useTableRows';
import useLocalTranslate from '../helpers/useLocalTranslate';
import useTableColumns from '../helpers/useTableColumns';
import { LocalApiResult } from '../types/GetResult';
import { TableRow } from '../types/TableRow';
import { GridRenderCellParams } from '@mui/x-data-grid';
import _ from 'lodash';
import { Box } from '../../../../components/MuiGenerals';
import CardFaceImage from './CardFaceImage';
import { tableConfig } from '../../../../utils/constant';
interface ViewTableProps {
  page: number;
  isInit: boolean;
  setPage: (page: number) => void;
  localApiResult: LocalApiResult;
}

const ViewTable = (props: ViewTableProps) => {
  const { isInit, localApiResult, setPage, page } = props;
  const { Tabs, t } = useLocalTranslate();

  const renderCardFaceCell = (params: GridRenderCellParams<any, any, any>) => {
    const row = params.row as TableRow;

    let url = '';

    const cardProfileLookUp = _.keyBy(
      [...localApiResult.merchantProgramCardProfileConfigs],
      (item) => item.programName + item.cardProfileName
    );

    const programLookUp = _.keyBy([...localApiResult.merchantProgramConfigs], 'programName');

    const cardProfileKey = row.programName + row.cardProfileName;
    const foundInCardProfile = !_.isEmpty(cardProfileLookUp[cardProfileKey]?.backgroundThumbnail);

    if (foundInCardProfile) {
      url = cardProfileLookUp[cardProfileKey].backgroundThumbnail;
    } else {
      url = programLookUp[row.programName]?.backgroundThumbnail;
    }

    return (
      <Box display="flex" flexDirection="column" alignItems="center" margin={'10px'}>
        <CardFaceImage url={url} />
        {!foundInCardProfile && `(${t('programDefault')})`}
      </Box>
    );
  };

  const { columns } = useTableColumns({ renderCardFaceCell });

  const { rows } = useTableRows({ localApiResult: localApiResult });

  const PAGE_SIZE = tableConfig.pageSize

  function paginateData(data: any, itemsPerPage: number, page: number) {
    if (!data || Object.keys(data).length === 0) return [];

    const pages = Math.ceil(data.length / itemsPerPage);
    const paginatedData = [];

    for (let i = 0; i < pages; i++) {
      const start = i * itemsPerPage;
      const end = start + itemsPerPage;
      paginatedData[i] = data.slice(start, end);
    }

    return paginatedData[page];
  }


  if (isInit) return <></>;

  return (
    <TableContainer>
      <Tabs>
        <Table
          rows={paginateData(rows, PAGE_SIZE, page)}
          columns={columns}
          page={page}
          onPageChange={setPage}
          getRowHeight={() => 'auto'}
          rowCount={rows.length}
        />
      </Tabs>
    </TableContainer>
  );
};

export default ViewTable;
