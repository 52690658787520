import { EnumRequestStatus } from '@wallet-manager/pfh-pmp-node-def-types/dist/src/DbModel/Eshop';
import { SingleSelection } from '../../../../../components';
import useLocalTranslate from '../../../../eshop/orderTransaction/helpers/useLocalTranslate';
import { TableColumnKey } from '../../../../eshop/orderTransaction/types/TableColumnKey';

interface IProps {
  value: any;
  onChange: (e: any) => void;
  onClearSelect: () => void;
}

const LedgerRequestStatusSingleSelection = (props: IProps) => {
  const { value, onChange, onClearSelect } = props;

  const { t, tc, translate } = useLocalTranslate();

  return (
    <SingleSelection
      label={tc('phSelection', { fieldName: t(TableColumnKey.LedgerRequestStatus) })}
      value={value}
      clearSelect={onClearSelect}
      onChange={onChange}
      enumData={EnumRequestStatus}
      nameFn={(name) => translate(`enumConstants.${name}`)}
    />
  )
}

export default LedgerRequestStatusSingleSelection