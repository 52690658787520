import { useTabs, useTranslation } from "../../../../hooks";
import { Iprefix } from "../../../../hooks/useTranslation";

const useLocalTranslate = () => {
  const translatePrefix: Iprefix = 'cardFaceOverview';
  const { t, tc } = useTranslation(translatePrefix);
  const { Tabs } = useTabs([{ name: tc('table'), value: '' }]);
  return {
    translatePrefix,
    t,
    tc,
    Tabs
  };
}

export default useLocalTranslate;