import { FilterTable } from "../../../components/Layout"
import ViewFilter from "../../../features/cobrandManagement/cardFaceOverview/component/ViewFilter"
import ViewTable from "../../../features/cobrandManagement/cardFaceOverview/component/ViewTable"
import useCardFaceOverView from "../../../features/cobrandManagement/cardFaceOverview/helpers/useCardFaceOverView"

const CardFaceOverview = () => {
  const {
    localApiResult,
    fields,
    isInit,
    page,
    setFields,
    onSearch,
    setPage
  } = useCardFaceOverView()
  return (
    <FilterTable
      filter={<ViewFilter
        fields={fields}
        setFields={setFields}
        onSearch={onSearch}
      />}
      table={<ViewTable
        page={page}
        setPage={setPage}
        localApiResult={localApiResult}
        isInit={isInit}
      />}
    />
  )
}

export default CardFaceOverview