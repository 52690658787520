import { EnumPaTransType } from '@wallet-manager/pfh-pmp-node-def-types/dist/src/DbModel/Reward';
import { ApiResult } from '../../../../../api/types';
import { useTranslation } from '../../../../../hooks';
import { toDisplayTime } from '../../../../../utils';
import { GetListRes } from '../types/GetListRes';
import { TableColumnKey } from '../types/TableColumnKeys';
import { TableRow } from '../types/TableRow';
import { getPaTransactionTypeDisplayValue } from '../../../../../helper/getPaTransacitonTypeDisplayValue';

interface UseTableRowsProps {
  apiList: ApiResult<GetListRes>;
}

type ConvertToTableRowsType = (apiList: ApiResult<GetListRes>) => TableRow[];

const useTableRows = (props: UseTableRowsProps) => {
  const { apiList } = props;
  const { translate } = useTranslation();

  const convertToTableRows: ConvertToTableRowsType = (apiList) => {
    if (!apiList || !apiList?.rows) return [];

    const tableRows = apiList.rows.map((row, index): TableRow => {
      const createDate = toDisplayTime(row.createdDate);
      const lastModifiedTime = toDisplayTime(row.lastModifiedDate);

      const transactionTypeDisplayValue = getPaTransactionTypeDisplayValue(
        row.transType,
        translate
      );

      return {
        id: index,
        [TableColumnKey.programAgentId]: row.programAgentId,
        [TableColumnKey.programName]: row.programName,
        [TableColumnKey.transactionType]: transactionTypeDisplayValue,
        [TableColumnKey.currency]: row.currency,
        [TableColumnKey.amount]: row.amount,
        [TableColumnKey.postBalance]: row.postBalance,
        [TableColumnKey.remarks]: row.remarks,
        [TableColumnKey.createdBy]: row.createdBy,
        [TableColumnKey.creationTime]: createDate,
        [TableColumnKey.lastModifiedTime]: lastModifiedTime,
        rawData: row,
      };
    });

    return tableRows;
  };

  const rows = convertToTableRows(apiList);

  return { rows, totalCounts: apiList.count, convertToTableRows };
};

export default useTableRows;
