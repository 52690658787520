import { EnumPaymentType } from '@wallet-manager/pfh-pmp-node-def-types/dist/src/DbModel/Eshop';

export const getPaymentTypeDisplayValue = (
  status: number,
  translateFunc: (val: any) => string
) => {
  const value = EnumPaymentType[status];

  if (!value) return '';

  return translateFunc(`enumConstants.${value}`)
};

