import { FilterFields, FilterFieldsKeys } from "./types/FilterFields";
import { LocalApiResult } from "./types/GetResult";

export const initFields: FilterFields = {
  [FilterFieldsKeys.ProgramNames]: [],
  [FilterFieldsKeys.CardProfileName]: ''
}

export const initLocalApiResult: LocalApiResult = {
  merchantCardProfile: [],
  merchantProgramCardProfileConfigs: [],
  merchantProgramConfigs: []
}