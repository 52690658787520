import { ChangeEvent, useState } from 'react';

import { DialogInOne, MpTextField } from '../../../components';
import { IdialogInOneProps } from '../../../components/DialogInOne';
import { GridBox } from '../../../components/Layout';
import { SelectChangeEvent } from '../../../components/MuiGenerals';
import PhoneCodeNumberBundle from '../../../components/PhoneCodeNumberBundle';
import { useAlerting, useTranslation } from '../../../hooks';
import useGridBoxInputValidation from '../../../hooks/useGridBoxInputValidation';
import { validateEmail } from '../../../utils/validators/emailValidation';
import { useZusDialogStore } from '../../../zustand/store';
import { apiObj as api, translateKeyObj as TK, translatePrefix, useZusParams } from './config';
import SourceSingleSelection from '../../../features/common/filterTable/components/Filters/SourceSingleSelection';

type Ifields = {
  email: string;
  phoneCountryCode: string;
  phoneNumber: string;
  source: string;
};

const initFields = {
  email: '',
  phoneCountryCode: '+852',
  phoneNumber: '',
  source: '',
};

export default function DialogCreateCustomer() {
  const { t, tc } = useTranslation(translatePrefix);
  const zusDialog = useZusDialogStore();
  const zusParams = useZusParams();
  const { validateGridBoxInput } = useGridBoxInputValidation();
  const { alerting } = useAlerting();

  const [fields, setFields] = useState(initFields);

  const handleCloseDialog = async () => {
    await zusDialog.close();

    setFields(initFields);
  };

  const onChange =
    (field: keyof Ifields) =>
    (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent<string>) => {
      setFields((prev) => ({ ...prev, [field]: e.target.value }));
    };

  const labelElePairArr: Array<[string, JSX.Element]> = [
    [
      TK.source,
      <SourceSingleSelection
        label={tc('phInputField', { fieldName: tc(TK.source) })}
        value={fields.source}
        onChange={onChange('source')}
        onClear={() => setFields((prev) => ({ ...prev, source: '' }))}
      />,
    ],
    [
      TK.emailAddress,
      <MpTextField
        label={tc('phInputField', { fieldName: t(TK.emailAddress) })}
        value={fields.email}
        onChange={onChange('email')}
      />,
    ],
    [
      TK.phoneNumber,
      <PhoneCodeNumberBundle
        phoneCode={{
          value: fields.phoneCountryCode,
          onChange: onChange('phoneCountryCode'),
        }}
        phoneNumber={{
          label: tc('phInputField', { fieldName: t(TK.phoneNumber) }),
          value: fields.phoneNumber,
          onChange: onChange('phoneNumber'),
        }}
      />,
    ],
  ];

  const handleConfirm = async () => {
    const validationMessage = validateGridBoxInput(labelElePairArr);

    if (validationMessage) {
      return alerting('warning', validationMessage);
    }

    const isValidEmail = validateEmail(fields.email);

    if (!isValidEmail) {
      return alerting('warning', t(TK.invalidEmailWarning));
    }

    const res = await api.createCustomer({ ...fields, source: Number(fields.source) });

    if (!res) {
      return;
    }

    alerting('success', t(TK.createCustomerSuccessfulMessage));

    await handleCloseDialog();

    zusParams.refetch();
  };

  const dialogConfig: IdialogInOneProps = {
    size: 'sm',
    title: t(TK.createCustomer),
    self: {
      open: zusDialog.match('createRequestDialog'),
      onClose: handleCloseDialog,
    },
    content: <GridBox labelElePairArr={labelElePairArr} columnCount={1} />,
    onConfirm: handleConfirm,
    onCancel: handleCloseDialog,
  };

  return <DialogInOne {...dialogConfig} />;
}
