import { cleanParams } from "../../../common/filterTable/helpers/cleanParams";
import { FilterFields } from "../types/FilterFields";
import { GetListParams } from "../types/GetListParams";

const useGetListParams = () => {
  const getListParams = (fields: FilterFields) => {
    const {
      programNames,
      cardProfileName,
    } = fields;

    const params: GetListParams = {
      programNames: programNames,
      cardProfileName: cardProfileName,
    };

    return cleanParams(params);
  };

  return {
    getListParams,
  };
};

export default useGetListParams;  