import React, { SetStateAction, useEffect, useState } from 'react';
import { FilterFields, FilterFieldsKeys } from '../types/FilterFields';
import { Filter } from '../../../common/filterTable/types';
import CollapsibleFilters from '../../../common/filterTable/components/Filters/CollapsibleFilters';
import { TableColumnKey } from '../types/TableColumnKey';
import CustomerIdTextField from '../../../common/filterTable/components/Filters/CustomerIdTextField';
import CustomerNumberTextField from '../../../common/filterTable/components/Filters/CustomerNumberTextField';
import { MpTextField } from '../../../../components';
import PurchaseStatusSingleSelection from '../../../common/filterTable/components/Filters/PurchaseStatusSingleSelection';
import LedgerRequestStatusSingleSelection from '../../../common/filterTable/components/Filters/LedgerRequestStatusSingleSelection';
import useLocalTranslate from '../helpers/useLocalTranslate';
import { initFields } from '../config';
import { useDatePicker } from '../../../../components/DatePicker';
import FilterSectionActionRow from '../../../common/filterTable/layout/FilterSectionActionRow';
import ToggleFilterButton from '../../../common/filterTable/components/Filters/CollapsibleFilters/components/ToggleFilterButton';
import useCollapsibleFilters from '../../../common/filterTable/components/Filters/CollapsibleFilters/hooks/useCollapsibleFilters';
import { useSearchParams } from 'react-router-dom';

interface FilterSectionProps {
  fields: FilterFields;
  setFields: React.Dispatch<SetStateAction<FilterFields>>;
  onSearch: () => void;
}

const FilterSection = (props: FilterSectionProps) => {
  const { fields, setFields, onSearch } = props;
  const { t, tc } = useLocalTranslate();
  const [searchParams] = useSearchParams()

  const { isCollapse, toggleFilterCollapse } = useCollapsibleFilters();

  const updateField = (fieldName: string, value: any) => {
    setFields((fields) => {
      return {
        ...fields,
        [fieldName]: value,
      };
    });
  };

  const DateObj = {
    CreationTime: useDatePicker(),
    LastModifiedTime: useDatePicker(),
  };

  useEffect(() => {
    // sync fields state with date picker
    setFields((fields) => {
      return {
        ...fields,
        [FilterFieldsKeys.CreatedDateFrom]: DateObj.CreationTime.start,
        [FilterFieldsKeys.CreatedDateTo]: DateObj.CreationTime.end,
        [FilterFieldsKeys.LastModifiedTimeFrom]: DateObj.LastModifiedTime.start,
        [FilterFieldsKeys.LastModifiedTimeTo]: DateObj.LastModifiedTime.end,
      };
    });
  }, [
    DateObj.CreationTime.start,
    DateObj.CreationTime.end,
    DateObj.LastModifiedTime.start,
    DateObj.LastModifiedTime.end,
  ]);

  const [initFetch, setInitFetch] = useState(true)

  useEffect(() => {
    if (!searchParams.has(FilterFieldsKeys.PurchaseOrderId)) { return; }
    const purchaseOrderId = searchParams.get(FilterFieldsKeys.PurchaseOrderId) as string
    updateField(FilterFieldsKeys.PurchaseOrderId, purchaseOrderId)
  }, [])

  useEffect(() => {
    if (!searchParams.has(FilterFieldsKeys.PurchaseOrderId)) { return; }
    if (!initFetch) { return; }
    if (fields[FilterFieldsKeys.PurchaseOrderId] === '') { return; }
    onSearch()
    setInitFetch(false)

  }, [fields[FilterFieldsKeys.PurchaseOrderId]])

  const onFilterReset = () => {
    setFields(initFields);
    DateObj.CreationTime.clearDate();
    DateObj.LastModifiedTime.clearDate();
  };

  const filters: Filter[] = [
    { labelKey: TableColumnKey.CreationTime, filter: <DateObj.CreationTime.Picker type="dateTime" /> },
    { labelKey: TableColumnKey.LastModifiedTime, filter: <DateObj.LastModifiedTime.Picker type="dateTime" /> },
    {
      labelKey: TableColumnKey.CustomerId, filter: (
        <CustomerIdTextField
          value={fields[FilterFieldsKeys.CustomerId]}
          onChange={(e) => updateField(FilterFieldsKeys.CustomerId, e.target.value)}
        />
      )
    },
    {
      labelKey: TableColumnKey.CustomerNumber, filter: (
        <CustomerNumberTextField
          value={fields[FilterFieldsKeys.CustomerNumber]}
          onChange={(e) => updateField(FilterFieldsKeys.CustomerNumber, e.target.value)}
        />
      )
    },
    {
      labelKey: TableColumnKey.PurchaseStatus, filter: (
        <PurchaseStatusSingleSelection
          value={fields[FilterFieldsKeys.PurchaseStatus]}
          onClearSelect={() => {
            setFields((fields) => ({ ...fields, [FilterFieldsKeys.PurchaseStatus]: '' }));
          }}
          onChange={(e) => {
            updateField(FilterFieldsKeys.PurchaseStatus, e.target.value);
          }}
        />
      )
    },
    {
      labelKey: TableColumnKey.LedgerRequestStatus, filter: (
        <LedgerRequestStatusSingleSelection
          value={fields[FilterFieldsKeys.LedgerRequestStatus]}
          onClearSelect={() => {
            setFields((fields) => ({ ...fields, [FilterFieldsKeys.LedgerRequestStatus]: '' }));
          }}
          onChange={(e) => {
            updateField(FilterFieldsKeys.LedgerRequestStatus, e.target.value);
          }}
        />

      )
    },
    {
      labelKey: TableColumnKey.PurchaseOrderId, filter: (
        <MpTextField
          label={tc('phInputField', { fieldName: t(TableColumnKey.PurchaseOrderId) })}
          value={fields[FilterFieldsKeys.PurchaseOrderId]}
          onChange={(e) => updateField(FilterFieldsKeys.PurchaseOrderId, e.target.value)}
        />
      )
    },
    {
      labelKey: TableColumnKey.LedgerTransactionId, filter: (
        <MpTextField
          label={tc('phInputField', { fieldName: t(TableColumnKey.LedgerTransactionId) })}
          value={fields[FilterFieldsKeys.LedgerTransactionId]}
          onChange={(e) => updateField(FilterFieldsKeys.LedgerTransactionId, e.target.value)}
        />
      )
    },
  ];

  return (
    <>
      <FilterSectionActionRow>
        <ToggleFilterButton
          isCollapse={isCollapse}
          onClick={toggleFilterCollapse}
        />
      </FilterSectionActionRow>
      <CollapsibleFilters
        filters={filters}
        isCollapse={isCollapse}
        onSearch={onSearch}
        onReset={onFilterReset}
      />
    </>
  );
};

export default FilterSection;
