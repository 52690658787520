export enum FilterFieldsKeys {
  CreatedDateFrom = 'createdDateFrom',
  CreatedDateTo = 'createdDateTo',
  LastModifiedTimeFrom = 'lastModifiedTimeFrom',
  LastModifiedTimeTo = 'lastModifiedTimeTo',
  CustomerId = 'customerId',
  CustomerNumber = 'customerNumber',
  PurchaseStatus = 'purchaseStatus',
  LedgerRequestStatus = 'ledgerRequestStatus',
  PurchaseOrderId = 'purchaseOrderId',
  LedgerTransactionId = 'ledgerTransactionId',
}

export interface FilterFields {
  [FilterFieldsKeys.CreatedDateFrom]: string;
  [FilterFieldsKeys.CreatedDateTo]: string;
  [FilterFieldsKeys.LastModifiedTimeFrom]: string;
  [FilterFieldsKeys.LastModifiedTimeTo]: string;
  [FilterFieldsKeys.CustomerNumber]: string;
  [FilterFieldsKeys.CustomerId]: string;
  [FilterFieldsKeys.PurchaseStatus]: string;
  [FilterFieldsKeys.LedgerRequestStatus]: string;
  [FilterFieldsKeys.PurchaseOrderId]: string;
  [FilterFieldsKeys.LedgerTransactionId]: string;
}
