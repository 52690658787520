import { GridRenderCellParams } from '@mui/x-data-grid';
import { COLUMN_WIDTH } from '../../../../constants/ColumnWidth';
import { useGenGridCol } from '../../../../utils/ComponentHelper';
import { TableColumnKey } from '../types/TableColumnKey';
import { MerchantPortalFeatureCode as FeatureCode } from '@wallet-manager/pfh-pmp-node-def-types';
import { Box, Container } from '../../../../components/MuiGenerals';
import CopyableText from '../../../../components/CopyableText';
import { OpIconButton } from '../../../../components/Button';
import { usePermission } from '../../../../hooks';
import { OpRedirect } from '../../../../assets/icons';

const useTableColumns = () => {
  const { hasPermission } = usePermission();
  const redirectPermission = FeatureCode.EShop.OrderTransaction.prefix;

  const purchaseOrderidCell = (params: GridRenderCellParams<any, any, any>) => {
    const purchaseOrderId = params.row[TableColumnKey.PurchaseOrderId];
    const redirectHandler = () =>
      window.open(
        `/orderTransaction?purchaseOrderId=${purchaseOrderId}`,
        '_blank',
        'noopener,noreferrer'
      );
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          width: '100%',
        }}
      >
        <CopyableText text={purchaseOrderId} sx={{ marginRight: '12px' }} />
        {hasPermission(redirectPermission) && (
          <OpIconButton title={''} size="1rem" svgUrl={OpRedirect} onClick={redirectHandler} />
        )}
      </Box>
    );
  };
  const columns = [
    useGenGridCol(TableColumnKey.PurchaseOrderId, {
      minWidth: COLUMN_WIDTH.PurchaseOrderId.MinWidth,
      renderCell: purchaseOrderidCell,
    }),
    useGenGridCol(TableColumnKey.CustomerId, {
      minWidth: COLUMN_WIDTH.CustomerId.MinWidth,
    }),
    useGenGridCol(TableColumnKey.Amount, {
      minWidth: COLUMN_WIDTH.Amount.MinWidth,
    }),
    useGenGridCol(TableColumnKey.PurchaseStatus, {
      minWidth: COLUMN_WIDTH.PurchaseStatus.MinWidth,
    }),
    useGenGridCol(TableColumnKey.EShopReferenceId, {
      minWidth: COLUMN_WIDTH.EShopReferenceId.MinWidth,
    }),
    useGenGridCol(TableColumnKey.CreatedDate, {
      minWidth: COLUMN_WIDTH.CreatedDate.MinWidth,
    }),
    useGenGridCol(TableColumnKey.LastModifiedDate, {
      minWidth: COLUMN_WIDTH.LastModifiedDate.MinWidth,
    }),
  ];

  return { columns };
};

export default useTableColumns;
