import { ChangeEvent, useEffect, useState } from 'react';

import {
  EnumIdvMethod,
  EnumQuestionType,
  EnumReferralCodeStatus,
} from '@wallet-manager/pfh-pmp-node-def-types/dist/src/DbModel/Master';

import { DialogInOne } from '../../../components';
import { IdialogInOneProps } from '../../../components/DialogInOne';
import { GridBox } from '../../../components/Layout';
import { Box, SelectChangeEvent } from '../../../components/MuiGenerals';
import RadioSelection from '../../../components/Selection/RadioSelection';
import { useAlerting, useTranslation } from '../../../hooks';
import { IenumKey } from '../../../hooks/useTranslation';
import { useZusDialogStore } from '../../../zustand/store';
import { apiObj as api, translateKeyObj as TK, translatePrefix, useZusParams } from './config';
import { getSourceDisplayValue } from '../../../helper/getSourceDisplayValue';

interface Ifields {
  status: string;
}

const initFields: Ifields = {
  status: '',
};

export default function DialogOperation() {
  const zusDialog = useZusDialogStore();

  const {
    programAgentId,
    distributorAgentId,
    programName,
    referralCode,
    kycIdvMethod,
    questionType,
    status,
    source,
  } = zusDialog.meta as {
    programAgentId: string;
    distributorAgentId: string;
    programName: string;
    referralCode: string;
    kycIdvMethod: number;
    questionType: number;
    status: string;
    source: string;
  };
  const [isReady, setIsReady] = useState(false);

  // const initFields: Ifields = {
  //   exchangeRate,
  //   exchangeRateUpperLimit,
  //   exchangeRateLowerLimit,
  // };

  const [fields, setFields] = useState<Ifields>(initFields);

  useEffect(() => {
    setFields((f) => ({
      ...f,
      status,
    }));
    setIsReady(!!status);
  }, [status]);

  const { t, te, tc, tqt, translate } = useTranslation(translatePrefix);

  const zusParams = useZusParams();

  const { alerting } = useAlerting();

  const onChange =
    (fieldName: keyof typeof fields) =>
    (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent<string>) => {
      setFields((f) => ({ ...f, [fieldName]: e.target.value }));
    };

  const labelElePairArr: Array<[string, JSX.Element]> = [
    [TK.source, <Box>{source}</Box>],
    [TK.programAgentId, <Box>{programAgentId}</Box>],
    [TK.distributorAgentId, <Box>{distributorAgentId}</Box>],
    [TK.programName, <Box>{programName}</Box>],
    [TK.referralCode, <Box>{referralCode}</Box>],
    [TK.idvMethod, <Box>{te(EnumIdvMethod[kycIdvMethod] as IenumKey)}</Box>],
    [TK.questionType, <Box>{tqt(EnumQuestionType[Number(questionType)])}</Box>],
    [
      TK.status,
      <RadioSelection
        radioItems={[
          {
            label: te(EnumReferralCodeStatus[1] as IenumKey),
            value: EnumReferralCodeStatus.Enable,
          },
          {
            label: te(EnumReferralCodeStatus[2] as IenumKey),
            value: EnumReferralCodeStatus.Disable,
          },
        ]}
        value={fields.status}
        onChange={onChange('status')}
      />,
    ],

    // [
    //   TK.exchangeRateUpperLimit,
    //   <MpTextFieldNumberOnly
    //     label={tc('phInputField', { fieldName: t(TK.exchangeRateUpperLimit) })}
    //     value={fields.exchangeRateUpperLimit}
    //     onChange={onChange('exchangeRateUpperLimit')}
    //     decimalCount={2}
    //   />,
    // ],

    // [
    //   TK.exchangeRateLowerLimit,
    //   <MpTextFieldNumberOnly
    //     label={tc('phInputField', { fieldName: t(TK.exchangeRateLowerLimit) })}
    //     value={fields.exchangeRateLowerLimit}
    //     onChange={onChange('exchangeRateLowerLimit')}
    //     decimalCount={2}
    //   />,
    // ],
  ];

  const dialogContent = <GridBox labelElePairArr={labelElePairArr} columnCount={1} />;

  const handleCloseDialog = async () => {
    await zusDialog.close();
    setFields(initFields);
  };

  const handleSubmit = async () => {
    if (fields.status === status) {
      return alerting('warning', tc('no_changes_made_warning'));
    }

    const res = await api.editRecord({
      status: Number(fields.status),
      referralCode,
      programName,
      kycIdvMethod,
    });

    if (!res) {
      return;
    }

    await handleCloseDialog();
    zusParams.refetch();
    alerting('success', t(TK.successEditRecord));
  };

  const dialogConfig: IdialogInOneProps = {
    title: tc('edit'),
    self: {
      open: zusDialog.match('operationDialog'),
      onClose: handleCloseDialog,
    },
    content: dialogContent,
    onConfirm: handleSubmit,
    onCancel: handleCloseDialog,

    size: 'sm',

    dialogActionStyling: { padding: '8px' },
  };

  if (!isReady) {
    return <></>;
  }

  return <DialogInOne {...dialogConfig} />;
}
