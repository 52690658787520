import { ChangeEvent, cloneElement, useState } from 'react';

import {
  EnumCustomerApplicationStatus,
  EnumCustomerApplicationType,
  EnumIdvMethod,
  EnumIdvStatus,
} from '@wallet-manager/pfh-pmp-node-def-types/dist/src/DbModel/Master';

import { SingleSelection } from '../../../components';
import { useDatePicker } from '../../../components/DatePicker';
import { Box, Container, SelectChangeEvent } from '../../../components/MuiGenerals';
import { MpTextField } from '../../../components/TextField';
import CollapsibleFilters from '../../../features/common/filterTable/components/Filters/CollapsibleFilters';
import ToggleFilterButton from '../../../features/common/filterTable/components/Filters/CollapsibleFilters/components/ToggleFilterButton';
import useCollapsibleFilters from '../../../features/common/filterTable/components/Filters/CollapsibleFilters/hooks/useCollapsibleFilters';
import CustomerLevelSingleSelection from '../../../features/common/filterTable/components/Filters/CustomerLevelSingleSelection';
import FilterSectionActionRow from '../../../features/common/filterTable/layout/FilterSectionActionRow';
import { Filter } from '../../../features/common/filterTable/types';
import { useTranslation } from '../../../hooks';
import { useGetProgram } from '../../../hooks/useProgram';
import { useGenFilterField } from '../../../utils/ComponentHelper';
import { EnumBoolean } from '../../../utils/constant';
import {
  Ifields,
  initFields,
  initZusParams,
  IsearchParam,
  translateKeyObj as TK,
  translatePrefix,
  useZusParams,
} from './config';
import ProgramNameMultipleSelection from '../../../features/common/filterTable/components/Filters/ProgramNameMultipleSelection';
import SourceSingleSelection from '../../../features/common/filterTable/components/Filters/SourceSingleSelection';

export default function ViewFilter() {
  const zusParams = useZusParams();
  const { t, tc } = useTranslation(translatePrefix);
  const [fields, setFields] = useState<Ifields>(initFields);

  const { EnumAllProgram } = useGetProgram();
  const { isCollapse, toggleFilterCollapse } = useCollapsibleFilters();

  const DateObj = {
    CreationTime: useDatePicker(),
    LastModifiedTime: useDatePicker(),
  };

  const getParams: () => IsearchParam = () => {
    const {
      applicationType: type,
      applicationStatus: status,
      emailAddress: email,
      // kycReferenceCode: kycRef,
      idvMethod: kycIdvMethod,
      idvStatus: kycIdvStatus,
      assignedCustomerNumber,
      programName: programNames,
      ...rest
    } = fields;

    const { start: createdDateFrom, end: createdDateTo } = DateObj.CreationTime;
    const { start: lastModifiedDateFrom, end: lastModifiedDateTo } = DateObj.LastModifiedTime;

    const getIsCustomerAssignedBooleanString = () => {
      if (assignedCustomerNumber === '') {
        return '';
      }

      return assignedCustomerNumber === 'yes' ? 'true' : 'false';
    };

    const isCustomerAssignedBooleanString = getIsCustomerAssignedBooleanString();

    return {
      ...initZusParams,
      ...rest,
      email,
      type,
      status,
      // kycRef,
      kycIdvMethod,
      kycIdvStatus,
      isCustomerAssigned: isCustomerAssignedBooleanString,
      createdDateFrom,
      createdDateTo,
      lastModifiedDateFrom,
      lastModifiedDateTo,
      programNames,
    };
  };

  const apiParams = getParams();

  const onSearch = () => {
    zusParams.setBody({ ...apiParams, page: 0 });
    zusParams.refetch();
  };

  const onReset = () => {
    setFields(initFields);
    DateObj.CreationTime.clearDate();
    DateObj.LastModifiedTime.clearDate();
  };

  const onChange =
    (field: keyof typeof fields) =>
    (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent<string>) => {
      setFields((fields) => ({ ...fields, [field]: e.target.value }));
    };

  const filters: Filter[] = [
    {
      labelKey: TK.creationTime,
      filter: <DateObj.CreationTime.Picker type="dateTime" />,
    },
    {
      labelKey: TK.lastModifiedTime,
      filter: <DateObj.LastModifiedTime.Picker type="dateTime" />,
    },
    {
      labelKey: TK.programName,
      filter: (
        <ProgramNameMultipleSelection
          value={fields.programName}
          onChange={(value: string[]) => {
            setFields((fields) => ({
              ...fields,
              programName: value,
              customerLevel: '',
            }));
          }}
          onClear={() => setFields((fields) => ({ ...fields, programName: [], customerLevel: '' }))}
        />
      ),
    },
    {
      labelKey: TK.customerLevel,
      filter: (
        <CustomerLevelSingleSelection
          label={tc('phSelection', { fieldName: t(TK.customerLevel) })}
          value={fields.customerLevel}
          onChange={onChange('customerLevel')}
          onClear={() => setFields((fields) => ({ ...fields, customerLevel: '' }))}
          programName={fields.programName}
        />
      ),
    },
    {
      labelKey: TK.referralCode,
      filter: (
        <MpTextField
          label={tc('phInputField', { fieldName: t(TK.referralCode) })}
          value={fields.referralCode}
          onChange={onChange('referralCode')}
        />
      ),
    },
    {
      labelKey: TK.applicationNumber,
      filter: (
        <MpTextField
          label={tc('phInputField', { fieldName: t(TK.applicationNumber) })}
          value={fields.applicationNumber}
          onChange={onChange('applicationNumber')}
        />
      ),
    },
    {
      labelKey: TK.applicationType,
      filter: (
        <SingleSelection
          label={tc('phSelection', { fieldName: t(TK.applicationType) })}
          value={fields.applicationType}
          onChange={onChange('applicationType')}
          enumData={EnumCustomerApplicationType}
          clearSelect={() => setFields((fields) => ({ ...fields, applicationType: '' }))}
        />
      ),
    },
    {
      labelKey: TK.applicationStatus,
      filter: (
        <SingleSelection
          label={tc('phSelection', { fieldName: t(TK.applicationStatus) })}
          value={fields.applicationStatus}
          onChange={onChange('applicationStatus')}
          enumData={EnumCustomerApplicationStatus}
          clearSelect={() => setFields((fields) => ({ ...fields, applicationStatus: '' }))}
        />
      ),
    },
    {
      labelKey: TK.customerId,
      filter: (
        <MpTextField
          label={tc('phInputField', { fieldName: t(TK.customerId) })}
          value={fields.customerId}
          onChange={onChange('customerId')}
        />
      ),
    },
    {
      labelKey: TK.customerName,
      filter: (
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <MpTextField
            label={tc('phInputField', { fieldName: t(TK.firstName) })}
            value={fields.firstName}
            onChange={onChange('firstName')}
            sx={{ marginRight: '8px' }}
          />

          <MpTextField
            label={tc('phInputField', { fieldName: t(TK.lastName) })}
            value={fields.lastName}
            onChange={onChange('lastName')}
            sx={{ marginLeft: '8px' }}
          />
        </Box>
      ),
    },
    {
      labelKey: TK.emailAddress,
      filter: (
        <MpTextField
          label={tc('phInputField', { fieldName: t(TK.emailAddress) })}
          value={fields.emailAddress}
          onChange={onChange('emailAddress')}
        />
      ),
    },
    {
      labelKey: TK.phoneNumber,
      filter: (
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <MpTextField
            label={tc('phInputField', { fieldName: t(TK.phoneCountryCode) })}
            value={fields.phoneCountryCode}
            onChange={onChange('phoneCountryCode')}
            sx={{ marginRight: '8px' }}
          />

          <MpTextField
            label={tc('phInputField', { fieldName: t(TK.phoneNumber) })}
            value={fields.phoneNumber}
            onChange={onChange('phoneNumber')}
            sx={{ marginLeft: '8px' }}
          />
        </Box>
      ),
    },
    {
      labelKey: TK.idvStatus,
      filter: (
        <SingleSelection
          label={tc('phSelection', { fieldName: t(TK.idvStatus) })}
          value={fields.idvStatus}
          onChange={onChange('idvStatus')}
          enumData={EnumIdvStatus}
          clearSelect={() => setFields((fields) => ({ ...fields, idvStatus: '' }))}
        />
      ),
    },
    {
      labelKey: TK.idvMethod,
      filter: (
        <SingleSelection
          label={tc('phSelection', { fieldName: t(TK.idvMethod) })}
          value={fields.idvMethod}
          onChange={onChange('idvMethod')}
          enumData={EnumIdvMethod}
          clearSelect={() => setFields((fields) => ({ ...fields, idvMethod: '' }))}
        />
      ),
    },
    {
      labelKey: TK.customerNumber,
      filter: (
        <MpTextField
          label={tc('phInputField', { fieldName: t(TK.customerNumber) })}
          value={fields.customerNumber}
          onChange={(e) => {
            onChange('customerNumber')(e);

            if (e.target.value !== '') {
              setFields((fields) => ({ ...fields, assignedCustomerNumber: '' }));
            }
          }}
        />
      ),
    },
    {
      labelKey: TK.assignedCustomerNumber,
      filter: (
        <SingleSelection
          label={tc('phSelection', { fieldName: t(TK.assignedCustomerNumber) })}
          value={fields.assignedCustomerNumber}
          onChange={onChange('assignedCustomerNumber')}
          enumData={EnumBoolean}
          clearSelect={() => setFields((fields) => ({ ...fields, assignedCustomerNumber: '' }))}
          isNoSorting
          nameFn={(name) => name}
          disabled={fields.customerNumber !== ''}
        />
      ),
    },
    {
      labelKey: TK.programAgentId,
      filter: (
        <MpTextField
          label={tc('phInputField', { fieldName: t(TK.programAgentId) })}
          value={fields.programAgentId}
          onChange={onChange('programAgentId')}
        />
      ),
    },
    {
      labelKey: TK.distributorAgentId,
      filter: (
        <MpTextField
          label={tc('phInputField', { fieldName: t(TK.distributorAgentId) })}
          value={fields.distributorAgentId}
          onChange={onChange('distributorAgentId')}
        />
      ),
    },
    {
      labelKey: TK.source,
      filter: (
        <SourceSingleSelection
          label={tc('phSelection', { fieldName: tc(TK.source) })}
          value={fields.source}
          onChange={onChange('source')}
          onClear={() => setFields((fields) => ({ ...fields, source: '' }))}
        />
      ),
    },
  ];

  return (
    <Container disableGutters maxWidth={false}>
      <FilterSectionActionRow>
        <ToggleFilterButton isCollapse={isCollapse} onClick={toggleFilterCollapse} />
      </FilterSectionActionRow>

      <CollapsibleFilters
        filters={filters}
        isCollapse={isCollapse}
        onSearch={onSearch}
        onReset={onReset}
      />
    </Container>
  );
}
