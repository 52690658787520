import { FilterTable } from '../../../components/Layout';
import useEShopOrder from '../../../features/eshop/eshopOrder/helpers/useEShopOrder'
import ViewFilter from '../../../features/eshop/eshopOrder/components/ViewFilter';
import ViewTable from '../../../features/eshop/eshopOrder/components/ViewTable';

const useEshopOrder = () => {

  const {
    list,
    page,
    fields,
    setFields,
    onExport,
    onPageChange,
    onFilterSearch,
  } = useEShopOrder();


  return (
    <FilterTable
      filter={<ViewFilter
        fields={fields}
        setFields={setFields}
        onSearch={onFilterSearch}
      />}
      table={<ViewTable
        list={list}
        page={page}
        onExport={onExport}
        onPageChange={onPageChange}
      />}
    />

  );
}
export default useEshopOrder;
