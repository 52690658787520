export enum FilterFieldsKeys {
    CreatedDateFrom = 'createdDateFrom',
    CreatedDateTo = 'createdDateTo',
    LastModifiedTimeFrom = 'lastModifiedTimeFrom',
    LastModifiedTimeTo = 'lastModifiedTimeTo',
    CustomerId = 'customerId',
    CustomerNumber = 'customerNumber',
    PurchaseStatus = 'status',
    PurchaseOrderId = 'purchaseOrderId',
    EShopReferenceId = 'eShopReferenceId'
}

export interface FilterFields {
    [FilterFieldsKeys.CreatedDateFrom]: string;
    [FilterFieldsKeys.CreatedDateTo]: string;
    [FilterFieldsKeys.LastModifiedTimeFrom]: string;
    [FilterFieldsKeys.LastModifiedTimeTo]: string;
    [FilterFieldsKeys.CustomerId]: string;
    [FilterFieldsKeys.PurchaseStatus]: string;
    [FilterFieldsKeys.PurchaseOrderId]: string;
    [FilterFieldsKeys.EShopReferenceId]: string;
    [FilterFieldsKeys.CustomerNumber]: string;
}
