import { useEffect, useState } from "react";
import useGetListParams from "./useGetListParams";
import useLocalTranslate from "./useLocalTranslate";
import { useZusDialogStore, useZusTranslatePrefixStore } from "../../../../zustand/store";
import { FilterFields } from "../types/FilterFields";
import { initCreateRecordField, initFields } from "../config";
import { useAlerting } from "../../../../hooks";
import { ApiResult } from "../../../../api/types";
import { GetListRes } from "../types/GetListRes";
import useTableRows from "./useTableRows";
import api from "../../../../api";
import { useQuery } from "react-query";
import { bigNumStrMulitpleDecimals, downloadFiles, getFullApiResponse } from "../../../../utils";
import { convertToExportRows } from "../../../common/filterTable/helpers/convertToExportRows";
import { TableRow } from "../types/TableRow";
import { EditRecordFields } from "../types/EditRecordField";
import { EnumInjectionFeeAmountType } from "@wallet-manager/pfh-pmp-node-def-types/dist/src/ApiEnum";
import { CreateRecordFields } from "../types/CreateRecordField";
import { useAppSelector } from "../../../../reducer/hooks";
import { selectProfile } from "../../../../reducer/profileSlice";
import useDialogCreateValidation from "./useDialogCreateValidation";
import useDialogEditValidation from "./useDialogEditValidation";

const useInjectionFeeConfiguration = () => {

  const zusDialog = useZusDialogStore();
  const { getListParams } = useGetListParams()
  const { alerting } = useAlerting()
  const { t, tc, translatePrefix } = useLocalTranslate()
  const { setTranslatePrefix } = useZusTranslatePrefixStore();
  const { email } = useAppSelector(selectProfile)

  const [page, setPage] = useState(0);
  const [fields, setFields] = useState<FilterFields>(initFields);
  const [list, setList] = useState<ApiResult<GetListRes>>({ rows: [], count: 0 });
  const [listParams, setListParams] = useState(getListParams(fields));
  const [selectedRow, setSelectedRow] = useState<TableRow>();
  const [editRecordFields, setEditRecordFields] = useState<EditRecordFields>(initCreateRecordField);
  const [isDialogCreateOpen, setIsDialogCreateOpen] = useState(false);
  const [createRecordFields, setCreateRecordFields] = useState<CreateRecordFields>(initCreateRecordField);

  useEffect(() => {
    setTranslatePrefix(translatePrefix)
    return () => {
      zusDialog.close();
    }
  }, [])

  const { totalCounts, convertToTableRows } = useTableRows({ apiList: list });

  const handleQuery = () => {
    zusDialog.openExtra('loadingDialog');
    const { currentTime, ...cleanParams } = listParams;
    return api.CobrandManagement.injectionFeeConfiguration.getAll(cleanParams);
  };

  const handleSuccess = (data: ApiResult<GetListRes>) => {
    if (!data) return;
    setList(data);
  };

  const handleSettled = () => zusDialog.closeExtra();

  useQuery(['getInjectionFeeConfiguration', listParams], handleQuery, {
    onSettled: handleSettled,
    onSuccess: handleSuccess,
  });

  const onExport = async () => {
    const params = getListParams(fields);
    const { ...cleanParams } = params;

    if (totalCounts === 0) {
      return alerting('error', tc('no_data_export'));
    }
    const apiFn = (page: number, pageSize: number, signal: any) =>
      api.CobrandManagement.injectionFeeConfiguration.exportTable({ ...cleanParams, page, pageSize }, { signal });

    const rawRes = await getFullApiResponse(apiFn, totalCounts, true);
    if (rawRes.length === 0) return;
    const omitKeys = ['id', 'rawData'];
    const tableRows = convertToTableRows({ rows: rawRes } as ApiResult<GetListRes>);
    const exportRows = convertToExportRows({ data: tableRows, omitKeys, translateFunc: t });

    downloadFiles(`Injection Fee Configuration`, exportRows, {});
  };

  const onPageChange = (page: number) => {
    setPage(page);
    setListParams({ ...listParams, page });
  };

  const onFilterSearch = () => {
    setPage(0);
    setListParams({
      ...getListParams(fields),
      page: 0,
      currentTime: Date.now(),
    });
  };


  //Dialog Edit
  const dialogEditValidate = useDialogEditValidation({ fields: editRecordFields })
  const handleEditRecordSubmit = async () => {

    const { allErrors } = dialogEditValidate.validate();

    if (allErrors.length > 0) {
      alerting('warning', allErrors[0]);
      return;
    }

    let changedField = 0
    const isFeeTypeChanged = selectedRow?.feeType !== EnumInjectionFeeAmountType[Number(editRecordFields.feeType)]
    const isLowerBoundAmountChanged = selectedRow?.lowerBoundAmount !== editRecordFields.lowerBoundAmount
    const isFeeChanged = selectedRow?.fee !== editRecordFields.fee

    const isNoChange = !isFeeTypeChanged && !isLowerBoundAmountChanged && !isFeeChanged
    if (isNoChange) {
      alerting('warning', t('pleaseModify'));
      return
    }

    if (isLowerBoundAmountChanged) { changedField = 1 }
    if (isFeeChanged) { changedField = 2 }
    if (isLowerBoundAmountChanged && isFeeChanged) { changedField = 3 }

    const res = await api.CobrandManagement.injectionFeeConfiguration.editRecord({
      programName: selectedRow?.rawData.programName,
      currency: selectedRow?.rawData.currency,
      lowerBoundCustodyAmount: bigNumStrMulitpleDecimals(
        editRecordFields.lowerBoundAmount,
        selectedRow?.rawData.custodyAmountDecimals ?? 0
      ),
      custodyAmountDecimals: selectedRow?.rawData.custodyAmountDecimals,
      feeAmount: editRecordFields.fee,
      feeAmountType: Number(editRecordFields.feeType),
      feeConfigId: selectedRow?.rawData.id,
      updateFields: changedField,
      feeDecimals: 0,
      updatedBy: email
    });
    if (!res) { return; }
    setListParams({ ...listParams, currentTime: Date.now() });
    setSelectedRow(() => undefined);
    alerting('success', t('editedSuccessfully'));
  }

  //Dialog Create
  const dialogCreateValidate = useDialogCreateValidation({ fields: createRecordFields })

  const handleCreateRecordSubmit = async () => {

    const { allErrors } = dialogCreateValidate.validate();

    if (allErrors.length > 0) {
      alerting('warning', allErrors[0]);
      return;
    }

    const lowerBoundCustodyAmount = bigNumStrMulitpleDecimals(
      createRecordFields.lowerBoundAmount,
      createRecordFields.custodyAmountDecimals
    )

    const res = await api.CobrandManagement.injectionFeeConfiguration.createRecord({
      programName: createRecordFields.programName,
      currency: createRecordFields.currency,
      lowerBoundCustodyAmount: lowerBoundCustodyAmount,
      custodyAmountDecimals: Number(createRecordFields.custodyAmountDecimals),
      feeAmount: createRecordFields.fee,
      feeDecimals: 0, //Hard code it now, backend will handle
      feeAmountType: Number(createRecordFields.feeType),
      createdBy: email
    })
    if (!res) { return; }
    setCreateRecordFields(initCreateRecordField)
    setIsDialogCreateOpen(false)
    setListParams({ ...listParams, currentTime: Date.now() });
    alerting('success', t('createdSuccessfully'))
  }

  return {
    page,
    fields,
    list,
    totalCounts,
    selectedRow,
    editRecordFields,
    isDialogCreateOpen,
    createRecordFields,
    setFields,
    onExport,
    onPageChange,
    onFilterSearch,
    setSelectedRow,
    setEditRecordFields,
    handleEditRecordSubmit,
    setIsDialogCreateOpen,
    setCreateRecordFields,
    handleCreateRecordSubmit
  }
}

export default useInjectionFeeConfiguration