import { EnumPurchaseOrderStatus } from '@wallet-manager/pfh-pmp-node-def-types/dist/src/DbModel/Eshop';
import { ApiResult } from '../../../../api/types';
import { getRiskLevelDisplayValue } from '../../../../helper/getRiskLevelDisplayValue';
import { useTranslation } from '../../../../hooks';
import { displayAmountCurrying, toDisplayTime } from '../../../../utils';
import { GetListRes } from '../types/GetListRes';
import { TableColumnKey } from '../types/TableColumnKey';
import { TableRow } from '../types/TableRow';
import { displayAmountWithCurrency } from '../../../../helper';

interface UseTableRowsProps {
  apiList: ApiResult<GetListRes>;
}

type ConvertToTableRowsType = (apiList: ApiResult<GetListRes>) => TableRow[];

const useTableRows = (props: UseTableRowsProps) => {
  const { apiList } = props;
  const { translate } = useTranslation();

  const convertToTableRows: ConvertToTableRowsType = (apiList) => {
    if (!apiList || !apiList?.rows) return [];

    const tableRows = apiList?.rows.map((row: any, index: any): TableRow => {
      const toDisplayAmount = displayAmountCurrying(row.decimals);
      const toDisplayHKDAmount = (amount: string) =>
        displayAmountWithCurrency(toDisplayAmount(amount), row.currency);
      return {
        id: index,
        [TableColumnKey.PurchaseOrderId]: row.id,
        [TableColumnKey.CustomerId]: row.customerId,
        [TableColumnKey.Amount]: toDisplayHKDAmount(row.totalAmount),
        [TableColumnKey.PurchaseStatus]: translate(
          `enumConstants.${EnumPurchaseOrderStatus[row.status]}`
        ),
        [TableColumnKey.EShopReferenceId]: row.orderId,
        [TableColumnKey.CreatedDate]: toDisplayTime(row.createdDate),
        [TableColumnKey.LastModifiedDate]: toDisplayTime(row.lastModifiedDate),
        rawData: row,
      };
    });

    return tableRows;
  };

  const rows = convertToTableRows(apiList);

  return { rows, totalCounts: apiList.count, convertToTableRows };
};

export default useTableRows;
