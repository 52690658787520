import { useEffect, useState } from 'react';

import { DialogInOne } from '../../../components';
import { IdialogInOneProps } from '../../../components/DialogInOne';
import { Box } from '../../../components/MuiGenerals';
import CancelButton from '../../../features/approval/components/CancelButton';
import { useAlerting, useTranslation } from '../../../hooks';
import { useZusDialogStore } from '../../../zustand/store';
import FullScreenDialogBtn from '../CustomerApplication/components/FullScreenDialogBtn';
import OperationDialogContent from '../CustomerApplication/components/OperationDialogContent';
import useOperationDialogContent from '../CustomerApplication/components/useOperationDialogContent';
import { apiObj as customerApplicationApi } from '../CustomerApplication/config';
import {
  apiObj as api,
  IapplicationDetail,
  translateKeyObj as TK,
  translatePrefix,
  useZusParams,
} from './config';

type IapiParams = {
  firstName: string;
  lastName: string;
  programName: string;
  customerId: string;
  idIssuedBy: string;
  idType: number;
  idNumber: string;
  dateOfBirth: string;
  referralCode: string;
  source: number;

  answers: Ianswers;
};

type Ianswers = {
  title: number;

  chineseName: string;
  alias: string;
  gender: string;

  nationality: string;

  idTypeOthers: string;

  idDateOfIssue: string;
  idDateOfExpiry: string;
  usCitizenship: boolean;
  maritalStatus: number;
  educationLevel: number;
  educationLevelOthers: string;
  purposeForApplying: number;
  purposeForApplyingOthers: string;
  authorizedToThirdParty: boolean;
  employmentStatus: number;
  companyName: string;
  jobTitle: number;
  jobTitleOthers: string;
  industry: number;
  industryOthers: string;
  employmentStartDate: string;
  officeTelephoneNumber: string;
  monthlySalaryHKD: string;
  otherIncomeHKD: string;
  residentialStatus: number;
  residentialStatusOthers: string;
  residentialTelephoneNumber: string;

  residentialAddressLine1: string;
  residentialAddressLine2: string;
  residentialAddressLine3: string;
  residentialPostalCode: string;
  residentialCity: string;
  residentialCountry: string;
  deliveryAddressLine1: string;
  deliveryAddressLine2: string;
  deliveryAddressLine3: string;
  deliveryPostalCode: string;
  deliveryCity: string;
  deliveryCountry: string;
  sameAsResidentialAddress: boolean;
};

const initFields = {
  programName: '',
  referralCode: '',
  title: '',
  firstName: '',
  lastName: '',
  chineseName: '',
  alias: '',
  gender: '',
  dateOfBirth: '',
  nationality: '',
  identificationDocumentType: '',
  identificationDocumentTypeOthers: '',
  placeOfIssue: '',
  identificationNumber: '',

  dateOfIssue: '',
  dateOfExpiry: '',
  usCitizenship: '',
  maritalStatus: '',
  educationalStatus: '',
  educationalStatusOthers: '',
  purposeForApplying: '',
  otherPurpose: '',
  isAuthorizedByYou: '',
  employmentStatus: '',
  companyName: '',
  jobTitle: '',
  jobTitleOthers: '',
  businessNatureOrIndustry: '',
  otherIndustry: '',
  employmentStartDate: '',
  officeTelephoneNumber: '',
  monthlySalaryInHkd: '',
  otherMonthlyIncome: '',
  residentialStatus: '',
  otherStatus: '',
  residentialTelephoneNumber: '',

  residentialAddressLine1: '',
  residentialAddressLine2: '',
  residentialAddressLine3: '',
  residentialPostalCode: '',
  residentialCity: '',
  residentialCountry: '',
  deliveryAddressLine1: '',
  deliveryAddressLine2: '',
  deliveryAddressLine3: '',
  deliveryPostalCode: '',
  deliveryCity: '',
  deliveryCountry: '',
};

export default function DialogCreateCustomerApplication() {
  const { tc, t } = useTranslation(translatePrefix);
  const zusDialog = useZusDialogStore();
  const zusParams = useZusParams();
  const { alerting } = useAlerting();

  const { customerId, emailAddress, phoneCountryCode, phoneNumber, details } = (zusDialog.meta ||
    {}) as {
    customerId: string | undefined;
    emailAddress: string | undefined;
    phoneCountryCode: string | undefined;
    phoneNumber: string | undefined;
    details: IapplicationDetail | undefined;
  };

  const {
    isSameAddress,
    setIsSameAddress,
    fields,
    setFields,
    getValidationMessage,
    labelElePairArrWithoutAddressSection,
    addressSectionLabelElePairArr,
    setApplicablePrograms,
  } = useOperationDialogContent<typeof initFields>({
    mode: 'create',
    initFields: initFields,
    createApplicationData: { customerId, emailAddress, phoneCountryCode, phoneNumber },
    generalDetail: details && {
      ...details,
      kycIdvManuallyVerifiedBy: details.kycIdvManuallyVerifiedBy || '',
      kycRef: {
        applicantId: details.kycRef.applicantId || '',
        workflowRunId: details.kycRef.workflowRunId || '',
      },
    },
    applicationAnswers: details?.answers,
  });

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (!customerId || details) {
      return;
    }

    setIsLoading(false);
  }, [customerId]);

  useEffect(() => {
    if (!details) {
      return;
    }

    customerApplicationApi
      .decryptIdNumber({ applicationNumber: details.applicationNumber })
      .then((res) => {
        if (!res) {
          return;
        }

        setFields((prev) => {
          const handleInvalidDate = (date: unknown) => {
            if (!date || date === '0000-00-00') {
              return '';
            }

            return date;
          };

          const returnObj = {
            ...prev,
            title: String(details.answers.title),
            firstName: details.firstName,
            lastName: details.lastName,
            chineseName: details.answers.chineseName,
            alias: details.answers.alias,
            gender: details.answers.gender,
            dateOfBirth: handleInvalidDate(details.dateOfBirth),
            nationality: details.answers.nationality,
            identificationDocumentType: String(details.idType),
            identificationDocumentTypeOthers: details.answers.idTypeOthers,
            placeOfIssue: details.idIssuedBy,
            identificationNumber: res.idNumber,

            dateOfIssue: handleInvalidDate(details.answers.idDateOfIssue),
            dateOfExpiry: handleInvalidDate(details.answers.idDateOfExpiry),
            usCitizenship: details.answers.usCitizenship ? 'yes' : 'no',
            maritalStatus: String(details.answers.maritalStatus),
            educationalStatus: String(details.answers.educationLevel),
            educationalStatusOthers: details.answers.educationLevelOthers,
            purposeForApplying: String(details.answers.purposeForApplying),
            otherPurpose: details.answers.purposeForApplyingOthers,
            isAuthorizedByYou: details.answers.authorizedToThirdParty ? 'yes' : 'no',
            employmentStatus: String(details.answers.employmentStatus),
            companyName: details.answers.companyName,
            jobTitle: details.answers.jobTitle ? String(details.answers.jobTitle) : '',
            jobTitleOthers: details.answers.jobTitleOthers,
            businessNatureOrIndustry: details.answers.industry
              ? String(details.answers.industry)
              : '',
            otherIndustry: details.answers.industryOthers,
            employmentStartDate: handleInvalidDate(details.answers.employmentStartDate),
            officeTelephoneNumber: details.answers.officeTelephoneNumber,
            monthlySalaryInHkd: details.answers.monthlySalaryHKD,
            otherMonthlyIncome: details.answers.otherIncomeHKD,
            residentialStatus: String(details.answers.residentialStatus),
            otherStatus: details.answers.residentialStatusOthers,
            residentialTelephoneNumber: details.answers.residentialTelephoneNumber,
            residentialAddressLine1: details.answers.residentialAddressLine1,
            residentialAddressLine2: details.answers.residentialAddressLine2,
            residentialAddressLine3: details.answers.residentialAddressLine3,
            residentialPostalCode: details.answers.residentialPostalCode,
            residentialCity: details.answers.residentialCity,
            residentialCountry: details.answers.residentialCountry,
            deliveryAddressLine1: details.answers.deliveryAddressLine1,
            deliveryAddressLine2: details.answers.deliveryAddressLine2,
            deliveryAddressLine3: details.answers.deliveryAddressLine3,
            deliveryPostalCode: details.answers.deliveryPostalCode,
            deliveryCity: details.answers.deliveryCity,
            deliveryCountry: details.answers.deliveryCountry,
          };

          const displayObj = Object.fromEntries(
            Object.entries(returnObj).map(([key, val]) => [key, val === undefined ? '' : val])
          );

          // if (!hasViewIdNumberPermission) {
          //   delete returnObj.identificationNumber;
          // }

          return displayObj;
        });
      })
      .finally(() => setIsLoading(false));
  }, [details]);

  if (isLoading) return <></>;

  const handleCloseDialog = async () => {
    await zusDialog.close();
    setFields(initFields);
    setIsSameAddress(false);
    setApplicablePrograms(undefined);
    setIsLoading(true);
  };

  const handleConfirm = async () => {
    const invalidMessage = getValidationMessage();

    if (invalidMessage) {
      return alerting('warning', invalidMessage);
    }

    const {
      firstName,
      lastName,
      programName,
      placeOfIssue: idIssuedBy,
      identificationDocumentType: idType,
      identificationNumber: idNumber,
      dateOfBirth,
      referralCode,
      ...rawAnswers
    } = fields;
    const enumNumberFields: (keyof typeof rawAnswers)[] = [
      'businessNatureOrIndustry',
      'educationalStatus',
      'jobTitle',
      'maritalStatus',
      'purposeForApplying',
      'residentialStatus',
      'title',
      'employmentStatus',
    ];
    const enumBooleanFields: (keyof typeof rawAnswers)[] = ['usCitizenship', 'isAuthorizedByYou'];

    const answersEntries = Object.entries(rawAnswers).map(([key, val]) => {
      if (enumNumberFields.find((enumKey) => enumKey === key)) {
        return val ? [key, Number(val)] : [key, null];
      }

      if (enumBooleanFields.find((enumKey) => enumKey === key)) {
        return val ? [key, val === 'yes'] : [key, null];
      }

      return [key, val];
    });

    const answers = Object.fromEntries(answersEntries);

    const {
      isAuthorizedByYou: authorizedToThirdParty,
      identificationDocumentTypeOthers: idTypeOthers,
      dateOfIssue: idDateOfIssue,
      dateOfExpiry: idDateOfExpiry,
      educationalStatus: educationLevel,
      educationalStatusOthers: educationLevelOthers,
      otherPurpose: purposeForApplyingOthers,
      businessNatureOrIndustry: industry,
      otherIndustry: industryOthers,
      monthlySalaryInHkd: monthlySalaryHKD,
      otherMonthlyIncome: otherIncomeHKD,
      otherStatus: residentialStatusOthers,
      ...rest
    } = answers;

    const createApplicationParams: IapiParams = {
      firstName,
      lastName,
      programName,
      customerId: customerId || '',
      idIssuedBy: idIssuedBy || '***N/A***',
      idType: Number(idType),
      idNumber,
      dateOfBirth,
      referralCode,
      source: Number(zusDialog?.meta?.rowData?.source),
      answers: {
        authorizedToThirdParty,
        idTypeOthers,
        idDateOfIssue,
        idDateOfExpiry,
        educationLevel,
        educationLevelOthers,
        purposeForApplyingOthers,
        industry,
        industryOthers,
        monthlySalaryHKD,
        otherIncomeHKD,
        residentialStatusOthers,
        sameAsResidentialAddress: isSameAddress,
        ...rest,
      },
    };

    const res = await api.createCustomerApplication(createApplicationParams);

    if (!res) {
      return;
    }

    alerting('success', t(TK.createApplicationSuccessfulMessage));

    await handleCloseDialog();

    zusParams.refetch();
  };

  const dialogConfig: IdialogInOneProps = {
    title: '',
    self: {
      open: zusDialog.match('createApplicationDialog'),
      onClose: handleCloseDialog,
    },
    content: (
      <OperationDialogContent
        labelElePairArrWithoutAddressSection={labelElePairArrWithoutAddressSection}
        addressSectionLabelElePairArr={addressSectionLabelElePairArr}
        isSameAddressBtnEnabled
        isSameAddress={isSameAddress}
        setIsSameAddress={setIsSameAddress}
        isApprovalProgressShown={false}
        isIdvSectionHidden
      />
    ),
    onConfirm: () => {},
    onCancel: () => {},
    size: 'xl',
    isFullScreen: true,
    isCancelHidden: true,
    isConfirmHidden: true,
    dialogActionStyling: {
      padding: /* isDetailsOrManualVerifyIdv ? '8px' : */ '16px 0px 16px 8px',
      borderTop: /* isDetailsOrManualVerifyIdv ? undefined : */ '1px solid #DDE1E3',
    },
    actionButtons: (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          width: '100%',
          padding: '0 calc(2rem - 8px)',
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <FullScreenDialogBtn
            children={tc('confirm_btn')}
            btnBgColor={'#5183FF'}
            onClick={handleConfirm}
          />

          <CancelButton onClick={handleCloseDialog} />
        </Box>
      </Box>
    ),
  };

  return <DialogInOne {...dialogConfig} />;
}
