import { EnumPaTransType } from '@wallet-manager/pfh-pmp-node-def-types/dist/src/DbModel/Reward';

export const getPaTransactionTypeDisplayValue = (
  transactionType: number,
  translate: (val: any) => string
) => {
  const transactionTypeTranslateKey = EnumPaTransType[Number(transactionType)];

  if (!transactionTypeTranslateKey) return '';

  return `${translate(`paTransactionType.${transactionTypeTranslateKey}`)}`;
};
