import React, { ReactNode, SetStateAction } from 'react';
import { FilterFields, FilterFieldsKeys } from '../types/FilterFields';
import { Filter } from '../../../../features/common/filterTable/types';
import ProgramAgentIdTextField from '../../../../features/common/filterTable/components/Filters/ProgramAgentIdTextField';
import CollapsibleFilters from '../../../../features/common/filterTable/components/Filters/CollapsibleFilters';
import PaRebateAdjustmentRequestStatusSingleSelection from '../../../../features/common/filterTable/components/Filters/PaRebateAdjustmentRequestStatusSingleSelection';
import PaTransactionTypeSingleSelection from '../../../../features/common/filterTable/components/Filters/RebateWalletBalanceHistoryTransactionTypeSingleSelection';

interface FilterSectionProps {
  fields: FilterFields;
  setFields: React.Dispatch<SetStateAction<FilterFields>>;
  onSearch: () => void;
  onReset: () => void;
  isCollapse?: boolean;
  DateObj: any;
}

const FilterSection = (props: FilterSectionProps) => {
  const { fields, setFields, onSearch, onReset, DateObj, isCollapse } = props;

  const updateField = (fieldName: string, value: any) => {
    setFields((fields) => {
      return {
        ...fields,
        [fieldName]: value,
      };
    });
  };

  const filters: Filter[] = [
    {
      labelKey: 'creationTime',
      filter: <DateObj.CreationTime.Picker type="dateTime" />,
    },
    {
      labelKey: 'lastModifiedTime',
      filter: <DateObj.LastModifiedTime.Picker type="dateTime" />,
    },
    {
      labelKey: FilterFieldsKeys.programAgentId,
      filter: (
        <ProgramAgentIdTextField
          value={fields[FilterFieldsKeys.programAgentId]}
          onChange={(e: any) => {
            updateField(FilterFieldsKeys.programAgentId, e.target.value);
          }}
        />
      ),
    },
    {
      labelKey: FilterFieldsKeys.status,
      filter: (
        <PaRebateAdjustmentRequestStatusSingleSelection
          value={fields[FilterFieldsKeys.status]}
          onChange={(e: any) => {
            updateField(FilterFieldsKeys.status, e.target.value);
          }}
          onClearSelect={() => {
            updateField(FilterFieldsKeys.status, '');
          }}
        />
      ),
    },
    {
      labelKey: FilterFieldsKeys.transactionType,
      filter: (
        <PaTransactionTypeSingleSelection
          value={fields[FilterFieldsKeys.transactionType]}
          onChange={(e: any) => {
            updateField(FilterFieldsKeys.transactionType, e.target.value);
          }}
          onClear={() => {
            setFields((fields) => ({ ...fields, [FilterFieldsKeys.transactionType]: '' }));
          }}
        />
      ),
    },
  ];

  return (
    <>
      <CollapsibleFilters
        isCollapse={isCollapse}
        filters={filters}
        onSearch={onSearch}
        onReset={onReset}
      />
    </>
  );
};

export default FilterSection;
