import { EnumAppSource } from '@wallet-manager/pfh-pmp-node-def-types/dist/src/DbModel/Master';
import { SingleSelection } from '../../../../../components';
import { useTranslation } from '../../../../../hooks';

interface IProps {
  label?: string;
  value: any;
  onChange: (e: any) => void;
  onClear: () => void;
}

const SourceSingleSelection = (props: IProps) => {
  const { label, value, onChange, onClear } = props;

  const { tc, translate } = useTranslation();

  return (
    <SingleSelection
      label={label || tc('phSelection', { fieldName: tc('source') })}
      value={value || ''}
      onChange={onChange}
      clearSelect={onClear}
      enumData={EnumAppSource}
      nameFn={(item) => translate(`EnumAppSource.${item}`)}
      isNoSorting
    />
  );
};

export default SourceSingleSelection;
