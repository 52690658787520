import { EnumRequestStatus } from '@wallet-manager/pfh-pmp-node-def-types/dist/src/DbModel/Eshop';

export const getLedgerRequestStatusDisplayValue = (
  status: number,
  translateFunc: (val: any) => string
) => {

  const value = EnumRequestStatus[status];

  if (!value) return '';

  return translateFunc(`enumConstants.${value}`)
};

