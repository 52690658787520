import { ApiResult } from '../../../../api/types';
import { getTransactionStepDisplayValue } from '../../../../helper/getTransactionStepDisplayValue';
import { displayAmountCurrying, toDisplayTime } from '../../../../utils';
import { GetListRes } from '../types/GetListRes';
import { TableColumnKey } from '../types/TableColumnKey';
import { TableRow } from '../types/TableRow';
import useLocalTranslate from './useLocalTranslate';
import { getPaymentTypeDisplayValue } from '../../../../helper/getPaymentTypeDisplayValue';
import { getPurchaseStatusDisplayValue } from '../../../../helper/getPurchaseStatusDisplayValue';
import { getLedgerRequestStatusDisplayValue } from '../../../../helper/getLedgerRequestStatusDisplayValue';


interface UseTableRowsProps {
  apiList: ApiResult<GetListRes>;
}

type ConvertToTableRowsType = (apiList: ApiResult<GetListRes>) => TableRow[];

const useTableRows = (props: UseTableRowsProps) => {
  const { apiList } = props;
  const { translate } = useLocalTranslate();

  const convertToTableRows: ConvertToTableRowsType = (apiList) => {
    if (!apiList || !apiList?.rows) return [];

    const tableRows = apiList?.rows.map((row: GetListRes, index: any): TableRow => {

      const toAmount = displayAmountCurrying(row.decimals);

      return {
        id: index,
        [TableColumnKey.PurchaseOrderId]: row.purchaseOrderId,
        [TableColumnKey.CustomerId]: row.customerId,
        [TableColumnKey.CustomerNumber]: row.customerNumber,
        [TableColumnKey.PaymentType]: getPaymentTypeDisplayValue(row.paymentType, translate),
        [TableColumnKey.Currency]: row.currency,
        [TableColumnKey.Amount]: toAmount(row.amount),
        [TableColumnKey.PurchaseStatus]: getPurchaseStatusDisplayValue(row.status, translate),
        [TableColumnKey.LedgerTransactionId]: row.ledgerTransId,
        [TableColumnKey.LedgerRequestStatus]: getLedgerRequestStatusDisplayValue(row.ledgerRequestStatus, translate),
        [TableColumnKey.MerchantOrderId]: row.merchantOrderId,
        [TableColumnKey.FreezeMerchantOrderId]: row.freezeOrderId,
        [TableColumnKey.From]: row.fromClientId,
        [TableColumnKey.To]: row.toClientId,
        [TableColumnKey.Sequence]: row.seq,
        [TableColumnKey.TransactionStep]: getTransactionStepDisplayValue(row.step, translate),
        [TableColumnKey.ErrorReason]: row.errorReason,
        [TableColumnKey.CreationTime]: toDisplayTime(row.createdDate),
        [TableColumnKey.LastModifiedTime]: toDisplayTime(row.lastModifiedDate),
        rawData: row
      };
    })

    return tableRows

  }

  const rows = convertToTableRows(apiList);

  return {
    rows,
    totalCounts: apiList.count,
    convertToTableRows
  };
};

export default useTableRows;
