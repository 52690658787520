import { useState, KeyboardEvent, useRef, useEffect } from 'react';
import ListSubheader from '@mui/material/ListSubheader';
import { GridSearchIcon } from '@mui/x-data-grid';
import { useTranslation } from "../../../../../hooks";
import { MpTextField } from "../../../../../components";
import {
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '../../../../../components/MuiGenerals';
import APIs from '../../../../../api';
import _ from 'lodash';

const SearchField = (props: any) => {
  const { searchLabel, setKeyword } = props;
  const onKeyDown = (e: KeyboardEvent<HTMLDivElement>) => {
    if (e.key !== 'Escape') {
      // Prevents autoselecting item while typing (default Select behaviour)
      e.stopPropagation();
    }
  };
  return (
    <ListSubheader>
      <MpTextField
        size="small"
        autoFocus
        placeholder={searchLabel}
        fullWidth
        InputProps={{
          startAdornment: <InputAdornment position="start" children={<GridSearchIcon />} />,
        }}
        onChange={(e) => setKeyword(e.target.value)}
        onKeyDown={onKeyDown}
      />
    </ListSubheader>
  );
}

interface IProps {
  label?: string;
  value: any;
  onChange: (value: string) => void;
}

const CardProfileNameSigleSelection = (p: IProps) => {
  const { tc } = useTranslation();
  const [keyword, setKeyword] = useState('');
  const [cardProfiles, setCardProfiles] = useState<string[]>([]);

  const searchLabel = tc('selectSearchLabel');

  const selectionRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const fetchCardProfile = async () => {
      const res = await APIs.MerchantPortal.query.getCardProfile();
      if (!_.isArray(res)) return;
      setCardProfiles(res);
    }
    fetchCardProfile();
  }, []);

  const manuItems = cardProfiles.sort((a, b) => a.localeCompare(b)).map((name, index) => {
    if (keyword && !name.toLowerCase().includes(keyword.toLowerCase())) {
      return [];
    }
    return (
      <MenuItem key={index} value={name}>
        {name}
      </MenuItem>
    )
  });

  return (
    <FormControl>
      <InputLabel>{p.label || tc('phSelection', { fieldName: tc('cardProfileName') })}</InputLabel>
      <Select
        value={p.value || ''}
        onChange={(e: SelectChangeEvent<string>) => p.onChange(e.target.value)}
        ref={selectionRef}
        onClose={() => {
          setKeyword('');
          const focusedDivElement = selectionRef.current?.querySelector('div') as HTMLDivElement;
          setTimeout(() => focusedDivElement.blur(), 150);
        }}
      >
        <SearchField searchLabel={searchLabel} setKeyword={setKeyword} />
        {manuItems}
      </Select>
    </FormControl>
  )

};

export default CardProfileNameSigleSelection;