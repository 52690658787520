import Reward from '../api/Reward';
import RewardBatch from '../views/Reward/RewardBatch';

export const COLUMN_WIDTH = {
  TxAddress: {
    MinWidth: 350,
  },
  TxHash: {
    MinWidth: 350,
  },

  ProgramName: {
    MinWidth: 250,
  },
  ProgramDisplayName: {
    MinWidth: 250,
  },
  ApplicationNumber: {
    MinWidth: 250,
  },
  CustomerId: {
    MinWidth: 250,
  },
  OrderId: {
    MinWidth: 250,
  },
  CreatedDate: {
    MinWidth: 170,
  },
  LastModifiedBy: {
    MinWidth: 150,
  },
  LastModifiedDate: {
    MinWidth: 170,
  },
  ClientId: {
    MinWidth: 150,
  },
  CustomerNumber: {
    MinWidth: 150,
  },
  ReferenceNumber: {
    MinWidth: 150,
  },
  ApprovalProgress: {
    MinWidth: 150,
  },
  TransactionStatus: {
    MinWidth: 150,
  },
  CreatedBy: {
    MinWidth: 150,
  },
  RiskLevel: {
    MinWidth: 150,
  },
  PartnerName: {
    MinWidth: 150,
  },
  RewardBatchId: {
    MinWidth: 200,
  },
  RewardOrderId: {
    MinWidth: 200,
  },
  PurchaseOrderId: {
    MinWidth: 150,
  },
  PurchaseStatus: {
    MinWidth: 150,
  },
  Amount: {
    MinWidth: 250,
  },
  EShopReferenceId: {
    MinWidth: 200,
  },
};
