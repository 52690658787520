import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useQuery } from 'react-query';

import { DataGrid, GridColDef, GridRenderCellParams, GridRowsProp } from '@mui/x-data-grid';
import {} from '@wallet-manager/pfh-pmp-node-def-types';
import { EnumCustomerStatus } from '@wallet-manager/pfh-pmp-node-def-types/dist/src/DbModel/Master';

import { LoadingDialog } from '../../../components';
import { ExportBtn } from '../../../components/Button';
import ButtonMenu from '../../../components/Button/ButtonMenu';
import { CustomPagination, NoRowsOverlay, TableTab } from '../../../components/Layout';
import { Container } from '../../../components/MuiGenerals';
import {
  useAlerting,
  useListMappingTransform,
  usePermission,
  useTranslation,
} from '../../../hooks';
import {
  downloadFiles,
  getFullApiResponse,
  toDisplayTime,
  transformObjWithNA,
} from '../../../utils';
import { dataGridColumnConfig, useGenGridCol } from '../../../utils/ComponentHelper';
import { dataGridDefaults } from '../../../utils/constant';
import { customSx } from '../../../utils/styling';
import { useZusDialogStore } from '../../../zustand/store';
import {
  apiObj as api,
  exportFileName,
  IapplicationDetail,
  IoperationBtnsStatus,
  ItableApiRes,
  omitKeyObj,
  PermissionKey,
  translateKeyObj as TK,
  translatePrefix,
  useZusParams,
} from './config';
import DialogCreateCustomerApplication from './DialogCreateCustomerApplication';
import DialogUpdateInfo from './DialogUpdateInfo';
import DialogUpdation from './DialogUpdation';
import { getSourceDisplayValue } from '../../../helper/getSourceDisplayValue';

function ViewTable() {
  const { alerting } = useAlerting();
  const { tc } = useTranslation(translatePrefix);
  const zusParams = useZusParams();
  const [count, setCount] = useState(0);
  const listMapping = useListMapping('export');
  const { hasPermission } = usePermission();

  const onExport = async () => {
    if (count === 0) {
      return alerting('error', tc('no_data_export'));
    }
    const apiFn = (page: number, pageSize: number, signal: any) =>
      api.export({ ...zusParams.body, page, pageSize }, { signal });
    const rawRes = await getFullApiResponse(apiFn, count, true);
    if (rawRes.length === 0) return;
    const omitKeys = omitKeyObj.export;

    const res = listMapping(rawRes, omitKeys);
    const config = {};
    downloadFiles(exportFileName, res, config);
  };
  return (
    <Container style={customSx.datagridContainer} maxWidth={false} disableGutters>
      <ExportBtn isShow={hasPermission(PermissionKey.Export)} onExport={onExport} />
      <TableTab>
        <TableList setCount={setCount} />
      </TableTab>
    </Container>
  );
}

function TableList(props: { setCount: Dispatch<SetStateAction<number>> }) {
  const { setCount } = props;

  const zusParams = useZusParams();
  const zusDialog = useZusDialogStore();
  const { t } = useTranslation(translatePrefix);
  const { hasPermission } = usePermission();

  const [opertaionBtnsStatus, setOpertaionBtnsStatus] = useState<IoperationBtnsStatus>({
    editInfo: false,
    createApplication: false,
  });

  const listRes = useQuery({
    queryKey: [translatePrefix, zusParams.body, zusParams.refetchCounter],
    queryFn: () => {
      zusDialog.openExtra('loadingDialog');
      return api.table(zusParams.body);
    },
    onSettled: () => zusDialog.closeExtra(),
  });

  const { rows = [], count = 0 } = listRes.data || {};

  const omitKeys = omitKeyObj.table;

  const listMapping = useListMapping('table');

  const content: GridRowsProp = listMapping(rows, omitKeys);

  useEffect(() => setCount(count), [listRes.data]);

  const renderOperationCell = (params: GridRenderCellParams<any, any, any>) => {
    const { status, customerId, phoneNumber, email, phoneCountryCode } = params.row[
      'rawData'
    ] as ItableApiRes;

    const fetchingApplicationDetails = async () => {
      const rawDetails = await api.applicationDetails({ customerId });

      if (!rawDetails) {
        return undefined;
      }

      const details = {
        ...transformObjWithNA(rawDetails, false),
        answers: transformObjWithNA(rawDetails.answers, false),
      } as IapplicationDetail;

      return details;
    };

    const handleUpdateInfoBtnClick = async () => {
      const details = await fetchingApplicationDetails();

      if (!details) {
        return;
      }

      zusDialog.open('operationDialog', { details });
    };

    const handleUpdateEmailAddressBtnClick = async () => {
      zusDialog.open('updateRecordDialog', { type: 'email', customerId, email });
    };

    const handleUpdatePhoneNoBtnClick = async () => {
      zusDialog.open('updateRecordDialog', {
        type: 'phone',
        customerId,
        phoneCountryCode,
        phoneNumber,
      });
    };

    const handleCreateCustomerApplicationBtn = async () => {
      let details = undefined;

      if (opertaionBtnsStatus.editInfo) {
        details = await fetchingApplicationDetails();

        if (!details) {
          return;
        }
      }

      zusDialog.open('createApplicationDialog', {
        details,
        customerId,
        emailAddress: email,
        phoneCountryCode,
        phoneNumber,
        rowData: params.row.rawData,
      });
    };

    return (
      <ButtonMenu
        title={t(TK.operation)}
        options={[
          {
            name: t(TK.updateInfo),
            onClickFn: handleUpdateInfoBtnClick,
            isDisabled: !opertaionBtnsStatus.editInfo,
          },
          {
            name: t(TK.createCustomerApplication),
            onClickFn: handleCreateCustomerApplicationBtn,
            noShow: !hasPermission(PermissionKey.CreateCustomerApplication),
            isDisabled: !opertaionBtnsStatus.createApplication,
          },
          {
            name: t(TK.updateEmailAddress),
            onClickFn: handleUpdateEmailAddressBtnClick,
            noShow: !hasPermission(PermissionKey.UpdateEmailAddress),
          },
          {
            name: t(TK.updatePhoneNo),
            onClickFn: handleUpdatePhoneNoBtnClick,
            noShow: !hasPermission(PermissionKey.UpdatePhoneNo),
          },
        ]}
        menuBtnOnClickFn={async () => {
          const res = await api.checkAllowEdit({ customerId });

          if (!res) {
            return;
          }

          setOpertaionBtnsStatus(res);
        }}
      />
    );
  };

  const columns: GridColDef[] = [
    useGenGridCol(TK.operation, {
      renderCell: renderOperationCell,
    }),
    useGenGridCol(TK.source, {
      minWidth: 150,
    }),
    useGenGridCol(TK.customerId, {
      minWidth: 150,
    }),
    useGenGridCol(TK.emailAddress, {
      minWidth: 150,
    }),
    useGenGridCol(TK.phoneNumber, {
      minWidth: 150,
    }),
    useGenGridCol(TK.customerStatus),
    useGenGridCol(TK.forcePasswordChange),
    useGenGridCol(TK.latestVersion),
    useGenGridCol(TK.createdBy, {
      minWidth: 150,
    }),
    useGenGridCol(TK.creationTime, { minWidth: 150 }),
    useGenGridCol(TK.lastModifiedTime, {
      minWidth: 150,
    }),
  ];

  const hasOperationColPermission =
    hasPermission(PermissionKey.UpdateInfo) ||
    hasPermission(PermissionKey.UpdateEmailAddress) ||
    hasPermission(PermissionKey.UpdatePhoneNo);

  const visibilityConfigArr = [
    {
      fieldName: TK.operation,
      hasPermission: hasOperationColPermission,
    },
  ];

  if (listRes.isLoading) return <LoadingDialog forceOpen={true} />;
  return (
    <>
      <DialogUpdation />
      <DialogUpdateInfo />
      <DialogCreateCustomerApplication />
      <DataGrid
        {...dataGridDefaults}
        rows={content}
        rowCount={count}
        columns={columns}
        page={zusParams.body.page}
        onPageChange={zusParams.setPage}
        components={{
          NoRowsOverlay,
          Footer: CustomPagination,
        }}
        componentsProps={{
          footer: { totalRecords: count },
        }}
        initialState={dataGridColumnConfig(visibilityConfigArr)}
      />
    </>
  );
}

const useListMapping = (mode: 'export' | 'table') => {
  const { t, tb, translate } = useTranslation('enumConstants');
  const listMappingTransform = useListMappingTransform(mode);

  const listMapping = (array: any[], omitKeys: string[] = []) => {
    const res = array.map((item: ItableApiRes) => {
      const displayPhoneNumber = item.phoneCountryCode + ' ' + item.phoneNumber;
      const mappedResult = [
        ['rawData', item],
        [TK.source, getSourceDisplayValue(item.source, translate)],
        [TK.customerId, item.customerId],
        [TK.emailAddress, item.email],
        [TK.phoneNumber, displayPhoneNumber],
        [TK.customerStatus, t(EnumCustomerStatus[item.status])],
        [TK.forcePasswordChange, tb(item.forcePasswordChange)],
        [TK.latestVersion, item.latestVersion],
        [TK.createdBy, item.createdBy],
        [TK.creationTime, toDisplayTime(item.createdDate)],
        [TK.lastModifiedTime, toDisplayTime(item.lastModifiedDate)],
      ].filter(([key]) => !omitKeys.includes(key));
      return mappedResult;
    });

    const output = res.map(listMappingTransform);
    return output;
  };
  return listMapping;
};

export default ViewTable;
