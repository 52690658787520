import React, { ReactNode, SetStateAction } from 'react';

import CollapsibleFilters from '../../../../../features/common/filterTable/components/Filters/CollapsibleFilters';
import ProgramAgentIdTextField from '../../../../../features/common/filterTable/components/Filters/ProgramAgentIdTextField';
import { Filter } from '../../../../../features/common/filterTable/types';
import { FilterFields, FilterFieldsKeys } from '../types/FilterFields';
import ProgramNameMultipleSelection from '../../../../../features/common/filterTable/components/Filters/ProgramNameMultipleSelection';
import PaTransactionTypeSingleSelection from '../../../../../features/common/filterTable/components/Filters/RebateWalletBalanceHistoryTransactionTypeSingleSelection';

interface FilterSectionProps {
  fields: FilterFields;
  setFields: React.Dispatch<SetStateAction<FilterFields>>;
  onSearch: () => void;
  onReset: () => void;
  isCollapse?: boolean;
  DateObj: any;
}

const FilterSection = (props: FilterSectionProps) => {
  const { fields, setFields, onSearch, onReset, isCollapse, DateObj } = props;

  const updateField = (fieldName: string, value: any) => {
    setFields((fields) => {
      return {
        ...fields,
        [fieldName]: value,
      };
    });
  };

  const filters: Filter[] = [
    { labelKey: 'creationTime', filter: <DateObj.CreationTime.Picker type="dateTime" /> },
    { labelKey: 'lastModifiedTime', filter: <DateObj.LastModifiedTime.Picker type="dateTime" /> },
    {
      labelKey: FilterFieldsKeys.ProgramAgentId,
      filter: (
        <ProgramAgentIdTextField
          value={fields[FilterFieldsKeys.ProgramAgentId]}
          onChange={(e) => updateField(FilterFieldsKeys.ProgramAgentId, e.target.value)}
        />
      ),
    },
    {
      labelKey: FilterFieldsKeys.ProgramName,
      filter: (
        <ProgramNameMultipleSelection
          value={fields[FilterFieldsKeys.ProgramName]}
          onClear={() => {
            setFields((fields) => ({ ...fields, [FilterFieldsKeys.ProgramName]: [] }));
          }}
          onChange={(value: string[]) => {
            updateField(FilterFieldsKeys.ProgramName, value);
          }}
        />
      ),
    },
    {
      labelKey: FilterFieldsKeys.TransactionType,
      filter: (
        <PaTransactionTypeSingleSelection
          value={fields[FilterFieldsKeys.TransactionType]}
          onChange={(e: any) => {
            updateField(FilterFieldsKeys.TransactionType, e.target.value);
          }}
          onClear={() => {
            setFields((fields) => ({ ...fields, [FilterFieldsKeys.TransactionType]: '' }));
          }}
        />
      ),
    },
  ];

  return (
    <>
      <CollapsibleFilters
        isCollapse={isCollapse}
        filters={filters}
        onSearch={onSearch}
        onReset={onReset}
      />
    </>
  );
};

export default FilterSection;
