import { ExportBtn } from '../../../../components/Button'
import { usePermission } from '../../../../hooks';
import { MerchantPortalFeatureCode as FeatureCode } from '@wallet-manager/pfh-pmp-node-def-types';
import Table from '../../../common/filterTable/components/Table'
import TableContainer from '../../../common/filterTable/layout/TableContainer'
import useLocalTranslate from '../helpers/useLocalTranslate';
import useTableColumns from '../helpers/useTableColumns';
import useTableRows from '../helpers/useTableRows';
import { ApiResult } from '../../../../api/types';
import { GetListRes } from '../types/GetListRes';

interface ViewTableProps {
  page: number
  list: ApiResult<GetListRes>
  onExport: () => Promise<void>
  onPageChange: (page: number) => void
}

const ViewTable = (props: ViewTableProps) => {
  const { page, list, onExport, onPageChange } = props;
  const { Tabs } = useLocalTranslate()

  const { hasPermission } = usePermission();
  const isShowExportBtn = hasPermission(FeatureCode.EShop.OrderTransaction.Export);

  const { columns } = useTableColumns()
  const { rows, totalCounts } = useTableRows({ apiList: list })

  return (
    <TableContainer>
      <ExportBtn onExport={onExport} isShow={isShowExportBtn} />
      <Tabs>
        <Table
          columns={columns}
          rows={rows}
          rowCount={totalCounts}
          page={page}
          onPageChange={onPageChange}
        />
      </Tabs>
    </TableContainer>
  )
}

export default ViewTable